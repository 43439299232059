import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class UtilService {
    constructor(private http: HttpClient) { }

    changeMonthFormatToNumber(date): number {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        months.findIndex(mon => mon === date);
        return months.findIndex(mon => mon === date) + 1;
    }

}