import { BrowserModule } from '@angular/platform-browser';


import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, ModalService, UserService } from './_services';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';



@NgModule({

  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // routing,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    NgxMaterialTimepickerModule,
  
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  
  ],
  declarations: [
    AppComponent,
    ModalComponent
  ],
  providers: [
    AuthSuperadminGuard,
    AuthVendorGuard,
    ModalService,
    UserService,

    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  //exports: [AuthSuperadminGuard, AuthVendorGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
