import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class HypertensionService {
    constructor(private http: HttpClient) { }


    save_hypertension(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/save`, formData).pipe(map(user => user));
    }

    save_intrestingcase(
        demographic_patient_initials,
        // demographic_subject_number,
        demographic_age_in_years,
        demographic_height,
        demographic_weight,
        demographic_gender,
        // demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        // demographic_socio_economic_status,

        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,

        case_presentation,
        personal_or_family_history,
        // past_medical_history,
        current_medication,

        case_presentation_file,
        personal_or_family_history_file,
        current_medication_file,
        medication_history_file,
        examination_findings_file,
        investigations_file,
        antihypertensive_drugs_file,
        lipid_lowering_drugs_file,
        diabetes_drugs_file,
        other_medications_file,
        follow_up_file,
        conclusion_remarks_file,

        // medication_history,
        other_medications,
        antihypertensive_drugs,
        lipid_lowering_drugs,
        diabetes_drugs,
        medication_history,
        examination_findings,
        investigations,
        // management,
        follow_up,
        conclusion_remarks,
    
       
        // chief_complaint,
        // comorbidities,
        // dyslipidemia,
        // chronic_liver_disease,
        // neurological_disorder,
        // cardiovascular_disorders,
        // hypothyroidism,
        // behavior_disorders,
        // diabetes,
        // chronic_kidney_disease,
        // asthma,
        // other,
        // other_description,
        // past_history,
        // Diet,
        // Smoking,
        // Smoking_Duration,
        // Alcohol,
        // Alcohol_Duration,
        // Others,
        // Sleep_Apnea,
        // Erectile_Dysfunction,
        // Exercise,
        // Exercise_Hours,
        // Exercise_Days,
        // Use_of_Computer,
        // Use_of_Smart_Phones,
        // Family_History,

        // current_medication,
        // sensitivities,
        // contraindicated_medication,
        // height_type,
        // height,
        // weight,
        // bmi,
        // bmi_type,
        // obesity,
        // pulse_rate,
        // respiratory_rate,
        // systemic_examination,
        // seated_blood_pressure,
        // ecg,
        // ecg_description,
        // echo,
        // echo_description,
        // cbc,
        // cbc_description,
        // renal_function_test,
        // renal_function_test_description,
        // serum_electrolyte_level,
        // serum_electrolyte_level_description,
        // total_cholestrol,
        // total_cholestrol_description,
        // ldl_c,
        // ldl_c_description,
        // hdl_c,
        // hdl_c_description,
        // triglycerides,
        // triglycerides_description,
        // fbs,
        // fbs_description,
        // ppbs,
        // ppbs_description,
        // urine_analysis,
        // urine_analysis_description,
        // serum_potassium,   
        // serum_potassium_description,   
        // serum_creatinine,
        // serum_creatinine_description,
        // e_glomerular_filtration_rate,
        // e_glomerular_filtration_rate_description,
        // hba1c,
        // hba1c_description,
        // albumin_creatinine_ratio,
        // albumin_creatinine_ratio_description,
        
        // other_information,
  
        // new_diagnosed,
        // known_case_of_hypertension,
        // hypertension_description,
        // refractory,
        // compliance_to_treatment,
        // BP_Goal,
        // Salt_reduction_2g_daily,
        // Moderation_of_alcohol,
        // Weight_loss,
        // Regular_exercise,
        // Lifestyle_Diet,
        // Stop_Tobacco_use,
        // Lipid_lowering_drugs,
        // Anti_Hypertensive_drugs,
        // Diabetes_drugs,
        // other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        // formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_height', demographic_height);
        formData.append('demographic_weight', demographic_weight);
        formData.append('demographic_gender', demographic_gender);
        // formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        // formData.append('demographic_socio_economic_status', demographic_socio_economic_status);

        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);


        formData.append('case_presentation', case_presentation);
        formData.append('personal_or_family_history', personal_or_family_history);
        // formData.append('past_medical_history', past_medical_history);
        formData.append('current_medication',  JSON.stringify(current_medication));

        formData.append('case_presentation_file', case_presentation_file);
        formData.append('personal_or_family_history_file', personal_or_family_history_file);
        formData.append('current_medication_file', current_medication_file);

        formData.append('medication_history_file', medication_history_file);
        formData.append('examination_findings_file', examination_findings_file);
        formData.append('investigations_file', investigations_file);
        formData.append('antihypertensive_drugs_file', antihypertensive_drugs_file);
        formData.append('lipid_lowering_drugs_file', lipid_lowering_drugs_file);
        formData.append('diabetes_drugs_file', diabetes_drugs_file);
        formData.append('other_medications_file', other_medications_file);
        formData.append('follow_up_file', follow_up_file);
        formData.append('conclusion_remarks_file', conclusion_remarks_file);


        // formData.append('medication_history', medication_history);
        formData.append('other_medications', JSON.stringify(other_medications));
        formData.append('antihypertensive_drugs', JSON.stringify(antihypertensive_drugs));
        formData.append('lipid_lowering_drugs', JSON.stringify(lipid_lowering_drugs));
        formData.append('diabetes_drugs', JSON.stringify(diabetes_drugs));
        formData.append('medication_history', JSON.stringify(medication_history));


        formData.append('examination_findings', examination_findings);
        formData.append('investigations', investigations);
        // formData.append('management', management);
        formData.append('follow_up', follow_up);
        formData.append('conclusion_remarks', conclusion_remarks);

    
       
        // formData.append('chief_complaint', chief_complaint);
        // formData.append('comorbidities', comorbidities);
        // formData.append('dyslipidemia', dyslipidemia);
        // formData.append('chronic_liver_disease', chronic_liver_disease);
        // formData.append('neurological_disorder', neurological_disorder);
        // formData.append('cardiovascular_disorders', cardiovascular_disorders);
        // formData.append('hypothyroidism', hypothyroidism);
        // formData.append('behavior_disorders', behavior_disorders);
        // formData.append('diabetes', diabetes);
        // formData.append('chronic_kidney_disease', chronic_kidney_disease);
        // formData.append('asthma', asthma);
        // formData.append('other', other);
        // formData.append('other_description', other_description);
        // formData.append('past_history', past_history);
        // formData.append('Diet', Diet);
        // formData.append('Smoking', Smoking);
        // formData.append('Smoking_Duration', Smoking_Duration);
        // formData.append('Alcohol', Alcohol);
        // formData.append('Alcohol_Duration', Alcohol_Duration);
        // formData.append('Others', Others);
        // formData.append('Sleep_Apnea', Sleep_Apnea);
        // formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        // formData.append('Exercise', Exercise);
        // formData.append('Exercise_Hours', Exercise_Hours);
        // formData.append('Exercise_Days', Exercise_Days);
        // formData.append('Use_of_Computer', Use_of_Computer);
        // formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        // formData.append('Family_History', Family_History);

        // formData.append('current_medication', JSON.stringify(current_medication));
        // formData.append('sensitivities', sensitivities);
        // formData.append('contraindicated_medication', contraindicated_medication);
        // formData.append('height_type', height_type);
        // formData.append('height', height);
        // formData.append('weight', weight);
        // formData.append('bmi', bmi);
        // formData.append('bmi_type', bmi_type);
        // formData.append('obesity', obesity);
        // formData.append('pulse_rate', pulse_rate);
        // formData.append('respiratory_rate', respiratory_rate);
        // formData.append('systemic_examination', systemic_examination);
        // formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        // formData.append('ecg', ecg);
        // formData.append('ecg_description', ecg_description);
        // formData.append('echo', echo);
        // formData.append('echo_description', echo_description);
        // formData.append('cbc', cbc);
        // formData.append('cbc_description', cbc_description);
        // formData.append('renal_function_test', renal_function_test);
        // formData.append('renal_function_test_description', renal_function_test_description);
        // formData.append('serum_electrolyte_level', serum_electrolyte_level);
        // formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        // formData.append('total_cholestrol', total_cholestrol);
        // formData.append('total_cholestrol_description', total_cholestrol_description);
        // formData.append('ldl_c', ldl_c);
        // formData.append('ldl_c_description', ldl_c_description);
        // formData.append('hdl_c', hdl_c);
        // formData.append('hdl_c_description', hdl_c_description);
        // formData.append('triglycerides', triglycerides);
        // formData.append('triglycerides_description', triglycerides_description);
        // formData.append('fbs', fbs);
        // formData.append('fbs_description', fbs_description);
        // formData.append('ppbs', ppbs);
        // formData.append('ppbs_description', ppbs_description);
        // formData.append('urine_analysis', urine_analysis);
        // formData.append('urine_analysis_description', urine_analysis_description);
        // formData.append('serum_potassium', serum_potassium);
        // formData.append('serum_potassium_description', serum_potassium_description);
        // formData.append('serum_creatinine', serum_creatinine);
        // formData.append('serum_creatinine_description', serum_creatinine_description);
        // formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        // formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        // formData.append('hba1c', hba1c);
        // formData.append('hba1c_description', hba1c_description);
        // formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        // formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        // formData.append('other_information', other_information);
        // formData.append('new_diagnosed', new_diagnosed);
        // formData.append('known_case_of_hypertension', known_case_of_hypertension);
        // formData.append('hypertension_description', hypertension_description);
        // formData.append('refractory', refractory);
        // formData.append('compliance_to_treatment', compliance_to_treatment);





        // formData.append('bp_goal', BP_Goal);
    
        // formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        // formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        // formData.append('Weight_loss', Weight_loss);
        // formData.append('Regular_exercise', Regular_exercise);
        // formData.append('Lifestyle_Diet', Lifestyle_Diet);
        // formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        // formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        // formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        // formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        // formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case`, formData).pipe(map(user => user));
    }

    save_hypertension_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
        

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/save`, formData).pipe(map(user => user));
    }

    update_hypertension(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
     

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/update/`+ id, formData).pipe(map(user => user));
    }

    update_intrestingcase(id,
        demographic_patient_initials,
        // demographic_subject_number,
        demographic_age_in_years,
        demographic_height,
        demographic_weight,
        demographic_gender,
        // demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,

        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,

        // demographic_socio_economic_status,
        case_presentation,
        personal_or_family_history,
        // past_medical_history,
        current_medication,

        case_presentation_file,
        personal_or_family_history_file,
        current_medication_file,
        medication_history_file,
        examination_findings_file,
        investigations_file,
        antihypertensive_drugs_file,
        lipid_lowering_drugs_file,
        diabetes_drugs_file,
        other_medications_file,
        follow_up_file,
        conclusion_remarks_file,

        // medication_history,
        other_medications,
        antihypertensive_drugs,
        lipid_lowering_drugs,
        diabetes_drugs,
        medication_history,

        examination_findings,
        investigations,
        // management,
        follow_up,
        conclusion_remarks,
      
      
        // chief_complaint,
        // comorbidities,
        // dyslipidemia,
        // chronic_liver_disease,
        // neurological_disorder,
        // cardiovascular_disorders,
        // hypothyroidism,
        // behavior_disorders,
        // diabetes,
        // chronic_kidney_disease,
        // asthma,
        // other,
        // other_description,
        // past_history,
        // Diet,
        // Smoking,
        // Smoking_Duration,
        // Alcohol,
        // Alcohol_Duration,
        // Others,
        // Sleep_Apnea,
        // Erectile_Dysfunction,
        // Exercise,
        // Exercise_Hours,
        // Exercise_Days,
        // Use_of_Computer,
        // Use_of_Smart_Phones,
        // Family_History,

        // current_medication,
        // sensitivities,
        // contraindicated_medication,
        // height_type,
        // height,
        // weight,
        // bmi,
        // bmi_type,
        // obesity,
        // pulse_rate,
        // respiratory_rate,
        // systemic_examination,
        // seated_blood_pressure,
        // ecg,
        // ecg_description,
        // echo,
        // echo_description,
        // cbc,
        // cbc_description,
        // renal_function_test,
        // renal_function_test_description,
        // serum_electrolyte_level,
        // serum_electrolyte_level_description,
        // total_cholestrol,
        // total_cholestrol_description,
        // ldl_c,
        // ldl_c_description,
        // hdl_c,
        // hdl_c_description,
        // triglycerides,
        // triglycerides_description,
        // fbs,
        // fbs_description,
        // ppbs,
        // ppbs_description,
        // urine_analysis,
        // urine_analysis_description,
        // serum_potassium,   
        // serum_potassium_description,   
        // serum_creatinine,
        // serum_creatinine_description,
        // e_glomerular_filtration_rate,
        // e_glomerular_filtration_rate_description,
        // hba1c,
        // hba1c_description,
        // albumin_creatinine_ratio,
        // albumin_creatinine_ratio_description,
        
        // other_information,
  
        // new_diagnosed,
        // known_case_of_hypertension,
        // hypertension_description,
        // refractory,
        // compliance_to_treatment,
        // BP_Goal,
        // Salt_reduction_2g_daily,
        // Moderation_of_alcohol,
        // Weight_loss,
        // Regular_exercise,
        // Lifestyle_Diet,
        // Stop_Tobacco_use,
        // Lipid_lowering_drugs,
        // Anti_Hypertensive_drugs,
        // Diabetes_drugs,
        // other_medication,
        followup_case,
     

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        // formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_height', demographic_height);
        formData.append('demographic_weight', demographic_weight);

        formData.append('demographic_gender', demographic_gender);
        // formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);


        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        // formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('case_presentation', case_presentation);
        formData.append('personal_or_family_history', personal_or_family_history);
        // formData.append('past_medical_history', past_medical_history);
        formData.append('current_medication', JSON.stringify(current_medication));

        formData.append('case_presentation_file', case_presentation_file);
        formData.append('personal_or_family_history_file', personal_or_family_history_file);
        formData.append('current_medication_file', current_medication_file);
        formData.append('medication_history_file', medication_history_file);
        formData.append('examination_findings_file', examination_findings_file);
        formData.append('investigations_file', investigations_file);

        formData.append('antihypertensive_drugs_file', antihypertensive_drugs_file);
        formData.append('lipid_lowering_drugs_file', lipid_lowering_drugs_file);
        formData.append('diabetes_drugs_file', diabetes_drugs_file);
        formData.append('other_medications_file', other_medications_file);
        formData.append('follow_up_file', follow_up_file);
        formData.append('conclusion_remarks_file', conclusion_remarks_file);



        // formData.append('medication_history', medication_history);
        formData.append('other_medications', JSON.stringify(other_medications));
        formData.append('antihypertensive_drugs', JSON.stringify(antihypertensive_drugs));
        formData.append('lipid_lowering_drugs', JSON.stringify(lipid_lowering_drugs));
        formData.append('diabetes_drugs', JSON.stringify(diabetes_drugs));
        formData.append('medication_history', JSON.stringify(medication_history));


        formData.append('examination_findings', examination_findings);
        formData.append('investigations', investigations);
        // formData.append('management', management);
        formData.append('follow_up', follow_up);
        formData.append('conclusion_remarks', conclusion_remarks);

      
        // formData.append('chief_complaint', chief_complaint);
        // formData.append('comorbidities', comorbidities);
        // formData.append('dyslipidemia', dyslipidemia);
        // formData.append('chronic_liver_disease', chronic_liver_disease);
        // formData.append('neurological_disorder', neurological_disorder);
        // formData.append('cardiovascular_disorders', cardiovascular_disorders);
        // formData.append('hypothyroidism', hypothyroidism);
        // formData.append('behavior_disorders', behavior_disorders);
        // formData.append('diabetes', diabetes);
        // formData.append('chronic_kidney_disease', chronic_kidney_disease);
        // formData.append('asthma', asthma);
        // formData.append('other', other);
        // formData.append('other_description', other_description);
        // formData.append('past_history', past_history);
        // formData.append('Diet', Diet);
        // formData.append('Smoking', Smoking);
        // formData.append('Smoking_Duration', Smoking_Duration);
        // formData.append('Alcohol', Alcohol);
        // formData.append('Alcohol_Duration', Alcohol_Duration);
        // formData.append('Others', Others);
        // formData.append('Sleep_Apnea', Sleep_Apnea);
        // formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        // formData.append('Exercise', Exercise);
        // formData.append('Exercise_Hours', Exercise_Hours);
        // formData.append('Exercise_Days', Exercise_Days);
        // formData.append('Use_of_Computer', Use_of_Computer);
        // formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        // formData.append('Family_History', Family_History);

        // formData.append('current_medication', JSON.stringify(current_medication));
        // formData.append('sensitivities', sensitivities);
        // formData.append('contraindicated_medication', contraindicated_medication);
        // formData.append('height_type', height_type);
        // formData.append('height', height);
        // formData.append('weight', weight);
        // formData.append('bmi', bmi);
        // formData.append('bmi_type', bmi_type);
        // formData.append('obesity', obesity);
        // formData.append('pulse_rate', pulse_rate);
        // formData.append('respiratory_rate', respiratory_rate);
        // formData.append('systemic_examination', systemic_examination);
        // formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        // formData.append('ecg', ecg);
        // formData.append('ecg_description', ecg_description);
        // formData.append('echo', echo);
        // formData.append('echo_description', echo_description);
        // formData.append('cbc', cbc);
        // formData.append('cbc_description', cbc_description);
        // formData.append('renal_function_test', renal_function_test);
        // formData.append('renal_function_test_description', renal_function_test_description);
        // formData.append('serum_electrolyte_level', serum_electrolyte_level);
        // formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        // formData.append('total_cholestrol', total_cholestrol);
        // formData.append('total_cholestrol_description', total_cholestrol_description);
        // formData.append('ldl_c', ldl_c);
        // formData.append('ldl_c_description', ldl_c_description);
        // formData.append('hdl_c', hdl_c);
        // formData.append('hdl_c_description', hdl_c_description);
        // formData.append('triglycerides', triglycerides);
        // formData.append('triglycerides_description', triglycerides_description);
        // formData.append('fbs', fbs);
        // formData.append('fbs_description', fbs_description);
        // formData.append('ppbs', ppbs);
        // formData.append('ppbs_description', ppbs_description);
        // formData.append('urine_analysis', urine_analysis);
        // formData.append('urine_analysis_description', urine_analysis_description);
        // formData.append('serum_potassium', serum_potassium);
        // formData.append('serum_potassium_description', serum_potassium_description);
        // formData.append('serum_creatinine', serum_creatinine);
        // formData.append('serum_creatinine_description', serum_creatinine_description);
        // formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        // formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        // formData.append('hba1c', hba1c);
        // formData.append('hba1c_description', hba1c_description);
        // formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        // formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        // formData.append('other_information', other_information);
        // formData.append('new_diagnosed', new_diagnosed);
        // formData.append('known_case_of_hypertension', known_case_of_hypertension);
        // formData.append('hypertension_description', hypertension_description);
        // formData.append('refractory', refractory);
        // formData.append('compliance_to_treatment', compliance_to_treatment);





        // formData.append('bp_goal', BP_Goal);
    
        // formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        // formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        // formData.append('Weight_loss', Weight_loss);
        // formData.append('Regular_exercise', Regular_exercise);
        // formData.append('Lifestyle_Diet', Lifestyle_Diet);
        // formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        // formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        // formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        // formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        // formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case/`+ id, formData).pipe(map(user => user));
    }

    update_hypertension_admin(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
        

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/update/`+ id, formData).pipe(map(user => user));
    }
    save_Followupcase(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/save`, formData).pipe(map(user => user));
    }

    save_Followupcase_intrestingcase(
        demographic_patient_initials,
        // demographic_subject_number,
        demographic_age_in_years,
        demographic_height,
        demographic_weight,
        demographic_gender,
        // demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,

        // demographic_socio_economic_status,
        case_presentation,
        personal_or_family_history,
        // past_medical_history,
        current_medication,

        case_presentation_file,
        personal_or_family_history_file,
        current_medication_file,
        medication_history_file,
        examination_findings_file,
        investigations_file,
        antihypertensive_drugs_file,
        lipid_lowering_drugs_file,
        diabetes_drugs_file,
        other_medications_file,
        follow_up_file,
        conclusion_remarks_file,

        // medication_history,
        other_medications,
        antihypertensive_drugs,
        lipid_lowering_drugs,
        diabetes_drugs,
        medication_history,

        examination_findings,
        investigations,
        // management,
        follow_up,
        conclusion_remarks,
     
      
        // chief_complaint,
        // comorbidities,
        // dyslipidemia,
        // chronic_liver_disease,
        // neurological_disorder,
        // cardiovascular_disorders,
        // hypothyroidism,
        // behavior_disorders,
        // diabetes,
        // chronic_kidney_disease,
        // asthma,
        // other,
        // other_description,
        // past_history,
        // Diet,
        // Smoking,
        // Smoking_Duration,
        // Alcohol,
        // Alcohol_Duration,
        // Others,
        // Sleep_Apnea,
        // Erectile_Dysfunction,
        // Exercise,
        // Exercise_Hours,
        // Exercise_Days,
        // Use_of_Computer,
        // Use_of_Smart_Phones,
        // Family_History,

        // current_medication,
        // sensitivities,
        // contraindicated_medication,
        // height_type,
        // height,
        // weight,
        // bmi,
        // bmi_type,
        // obesity,
        // pulse_rate,
        // respiratory_rate,
        // systemic_examination,
        // seated_blood_pressure,
        // ecg,
        // ecg_description,
        // echo,
        // echo_description,
        // cbc,
        // cbc_description,
        // renal_function_test,
        // renal_function_test_description,
        // serum_electrolyte_level,
        // serum_electrolyte_level_description,
        // total_cholestrol,
        // total_cholestrol_description,
        // ldl_c,
        // ldl_c_description,
        // hdl_c,
        // hdl_c_description,
        // triglycerides,
        // triglycerides_description,
        // fbs,
        // fbs_description,
        // ppbs,
        // ppbs_description,
        // urine_analysis,
        // urine_analysis_description,
        // serum_potassium,   
        // serum_potassium_description,   
        // serum_creatinine,
        // serum_creatinine_description,
        // e_glomerular_filtration_rate,
        // e_glomerular_filtration_rate_description,
        // hba1c,
        // hba1c_description,
        // albumin_creatinine_ratio,
        // albumin_creatinine_ratio_description,
        
        // other_information,
  
        // new_diagnosed,
        // known_case_of_hypertension,
        // hypertension_description,
        // refractory,
        // compliance_to_treatment,
        // BP_Goal,
        // Salt_reduction_2g_daily,
        // Moderation_of_alcohol,
        // Weight_loss,
        // Regular_exercise,
        // Lifestyle_Diet,
        // Stop_Tobacco_use,
        // Lipid_lowering_drugs,
        // Anti_Hypertensive_drugs,
        // Diabetes_drugs,
        // other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        // formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_height', demographic_height);
        formData.append('demographic_weight', demographic_weight);
        formData.append('demographic_gender', demographic_gender);
        // formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        // formData.append('demographic_socio_economic_status', demographic_socio_economic_status);

        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);

        formData.append('case_presentation', case_presentation);
        formData.append('personal_or_family_history', personal_or_family_history);
        // formData.append('past_medical_history', past_medical_history);
        formData.append('current_medication', JSON.stringify(current_medication));


        formData.append('case_presentation_file', case_presentation_file);
        formData.append('personal_or_family_history_file', personal_or_family_history_file);
        formData.append('current_medication_file', current_medication_file);
        formData.append('medication_history_file', medication_history_file);
        formData.append('examination_findings_file', examination_findings_file);
        formData.append('investigations_file', investigations_file);
        formData.append('antihypertensive_drugs_file', antihypertensive_drugs_file);
        formData.append('lipid_lowering_drugs_file', lipid_lowering_drugs_file);
        formData.append('diabetes_drugs_file', diabetes_drugs_file);
        formData.append('other_medications_file', other_medications_file);
        formData.append('follow_up_file', follow_up_file);
        formData.append('conclusion_remarks_file', conclusion_remarks_file);





        // formData.append('medication_history', medication_history);
        formData.append('other_medications', JSON.stringify(other_medications));
        formData.append('antihypertensive_drugs', JSON.stringify(antihypertensive_drugs));
        formData.append('lipid_lowering_drugs', JSON.stringify(lipid_lowering_drugs));
        formData.append('diabetes_drugs', JSON.stringify(diabetes_drugs));
        formData.append('medication_history', JSON.stringify(medication_history));


        formData.append('examination_findings', examination_findings);
        formData.append('investigations', investigations);
        // formData.append('management', management);
        formData.append('follow_up', follow_up);
        formData.append('conclusion_remarks', conclusion_remarks);

     
        
        // formData.append('chief_complaint', chief_complaint);
        // formData.append('comorbidities', comorbidities);
        // formData.append('dyslipidemia', dyslipidemia);
        // formData.append('chronic_liver_disease', chronic_liver_disease);
        // formData.append('neurological_disorder', neurological_disorder);
        // formData.append('cardiovascular_disorders', cardiovascular_disorders);
        // formData.append('hypothyroidism', hypothyroidism);
        // formData.append('behavior_disorders', behavior_disorders);
        // formData.append('diabetes', diabetes);
        // formData.append('chronic_kidney_disease', chronic_kidney_disease);
        // formData.append('asthma', asthma);
        // formData.append('other', other);
        // formData.append('other_description', other_description);
        // formData.append('past_history', past_history);
        // formData.append('Diet', Diet);
        // formData.append('Smoking', Smoking);
        // formData.append('Smoking_Duration', Smoking_Duration);
        // formData.append('Alcohol', Alcohol);
        // formData.append('Alcohol_Duration', Alcohol_Duration);
        // formData.append('Others', Others);
        // formData.append('Sleep_Apnea', Sleep_Apnea);
        // formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        // formData.append('Exercise', Exercise);
        // formData.append('Exercise_Hours', Exercise_Hours);
        // formData.append('Exercise_Days', Exercise_Days);
        // formData.append('Use_of_Computer', Use_of_Computer);
        // formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        // formData.append('Family_History', Family_History);

        // formData.append('current_medication', JSON.stringify(current_medication));
        // formData.append('sensitivities', sensitivities);
        // formData.append('contraindicated_medication', contraindicated_medication);
        
        // formData.append('height_type', height_type);
        // formData.append('height', height);
        // formData.append('weight', weight);
        // formData.append('bmi', bmi);
        // formData.append('bmi_type', bmi_type);
        // formData.append('obesity', obesity);
        // formData.append('pulse_rate', pulse_rate);
        // formData.append('respiratory_rate', respiratory_rate);
        // formData.append('systemic_examination', systemic_examination);
        // formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        // formData.append('ecg', ecg);
        // formData.append('ecg_description', ecg_description);
        // formData.append('echo', echo);
        // formData.append('echo_description', echo_description);
        // formData.append('cbc', cbc);
        // formData.append('cbc_description', cbc_description);
        // formData.append('renal_function_test', renal_function_test);
        // formData.append('renal_function_test_description', renal_function_test_description);
        // formData.append('serum_electrolyte_level', serum_electrolyte_level);
        // formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        // formData.append('total_cholestrol', total_cholestrol);
        // formData.append('total_cholestrol_description', total_cholestrol_description);
        // formData.append('ldl_c', ldl_c);
        // formData.append('ldl_c_description', ldl_c_description);
        // formData.append('hdl_c', hdl_c);
        // formData.append('hdl_c_description', hdl_c_description);
        // formData.append('triglycerides', triglycerides);
        // formData.append('triglycerides_description', triglycerides_description);
        // formData.append('fbs', fbs);
        // formData.append('fbs_description', fbs_description);
        // formData.append('ppbs', ppbs);
        // formData.append('ppbs_description', ppbs_description);
        // formData.append('urine_analysis', urine_analysis);
        // formData.append('urine_analysis_description', urine_analysis_description);
        // formData.append('serum_potassium', serum_potassium);
        // formData.append('serum_potassium_description', serum_potassium_description);
        // formData.append('serum_creatinine', serum_creatinine);
        // formData.append('serum_creatinine_description', serum_creatinine_description);
        // formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        // formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        // formData.append('hba1c', hba1c);
        // formData.append('hba1c_description', hba1c_description);
        // formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        // formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        // formData.append('other_information', other_information);
        // formData.append('new_diagnosed', new_diagnosed);
        // formData.append('known_case_of_hypertension', known_case_of_hypertension);
        // formData.append('hypertension_description', hypertension_description);
        // formData.append('refractory', refractory);
        // formData.append('compliance_to_treatment', compliance_to_treatment);





        // formData.append('bp_goal', BP_Goal);
    
        // formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        // formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        // formData.append('Weight_loss', Weight_loss);
        // formData.append('Regular_exercise', Regular_exercise);
        // formData.append('Lifestyle_Diet', Lifestyle_Diet);
        // formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        // formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        // formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        // formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        // formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case`, formData).pipe(map(user => user));
    }

    save_Followupcase_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
        

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/save`, formData).pipe(map(user => user));
    }

    update_followupcase(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/update/`+ id, formData).pipe(map(user => user));
    }

    update_followupcase_intrestingcase(id,
        demographic_patient_initials,
        // demographic_subject_number,
        demographic_age_in_years,
        demographic_height,
        demographic_weight,
        demographic_gender,
        // demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,


        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,

        // demographic_socio_economic_status,
        case_presentation,
        personal_or_family_history,
        // past_medical_history,
        current_medication,

        case_presentation_file,
        personal_or_family_history_file,
        current_medication_file,
        medication_history_file,
        examination_findings_file,
        investigations_file,
        antihypertensive_drugs_file,
        lipid_lowering_drugs_file,
        diabetes_drugs_file,
        other_medications_file,
        follow_up_file,
        conclusion_remarks_file,

        // medication_history,
        other_medications,
        antihypertensive_drugs,
        lipid_lowering_drugs,
        diabetes_drugs,
        medication_history,
        examination_findings,
        investigations,
        // management,
        follow_up,
        conclusion_remarks,
        
        
        // chief_complaint,
        // comorbidities,
        // dyslipidemia,
        // chronic_liver_disease,
        // neurological_disorder,
        // cardiovascular_disorders,
        // hypothyroidism,
        // behavior_disorders,
        // diabetes,
        // chronic_kidney_disease,
        // asthma,
        // other,
        // other_description,
        // past_history,
        // Diet,
        // Smoking,
        // Smoking_Duration,
        // Alcohol,
        // Alcohol_Duration,
        // Others,
        // Sleep_Apnea,
        // Erectile_Dysfunction,
        // Exercise,
        // Exercise_Hours,
        // Exercise_Days,
        // Use_of_Computer,
        // Use_of_Smart_Phones,
        // Family_History,

        // current_medication,
        // sensitivities,
        // contraindicated_medication,
        // height_type,
        // height,
        // weight,
        // bmi,
        // bmi_type,
        // obesity,
        // pulse_rate,
        // respiratory_rate,
        // systemic_examination,
        // seated_blood_pressure,
        // ecg,
        // ecg_description,
        // echo,
        // echo_description,
        // cbc,
        // cbc_description,
        // renal_function_test,
        // renal_function_test_description,
        // serum_electrolyte_level,
        // serum_electrolyte_level_description,
        // total_cholestrol,
        // total_cholestrol_description,
        // ldl_c,
        // ldl_c_description,
        // hdl_c,
        // hdl_c_description,
        // triglycerides,
        // triglycerides_description,
        // fbs,
        // fbs_description,
        // ppbs,
        // ppbs_description,
        // urine_analysis,
        // urine_analysis_description,
        // serum_potassium,   
        // serum_potassium_description,   
        // serum_creatinine,
        // serum_creatinine_description,
        // e_glomerular_filtration_rate,
        // e_glomerular_filtration_rate_description,
        // hba1c,
        // hba1c_description,
        // albumin_creatinine_ratio,
        // albumin_creatinine_ratio_description,
        
        // other_information,
  
        // new_diagnosed,
        // known_case_of_hypertension,
        // hypertension_description,
        // refractory,
        // compliance_to_treatment,
        // BP_Goal,
        // Salt_reduction_2g_daily,
        // Moderation_of_alcohol,
        // Weight_loss,
        // Regular_exercise,
        // Lifestyle_Diet,
        // Stop_Tobacco_use,
        // Lipid_lowering_drugs,
        // Anti_Hypertensive_drugs,
        // Diabetes_drugs,
        // other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        // formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_height', demographic_height);
        formData.append('demographic_weight', demographic_weight);

        formData.append('demographic_gender', demographic_gender);
        // formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        // formData.append('demographic_socio_economic_status', demographic_socio_economic_status);

        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);

        formData.append('case_presentation', case_presentation);
        formData.append('personal_or_family_history', personal_or_family_history);
        // formData.append('past_medical_history', past_medical_history);
        formData.append('current_medication', JSON.stringify(current_medication));

        formData.append('case_presentation_file', case_presentation_file);
        formData.append('personal_or_family_history_file', personal_or_family_history_file);
        formData.append('current_medication_file', current_medication_file);
        formData.append('medication_history_file', medication_history_file);
        formData.append('examination_findings_file', examination_findings_file);
        formData.append('investigations_file', investigations_file);
        formData.append('antihypertensive_drugs_file', antihypertensive_drugs_file);
        formData.append('lipid_lowering_drugs_file', lipid_lowering_drugs_file);
        formData.append('diabetes_drugs_file', diabetes_drugs_file);
        formData.append('other_medications_file', other_medications_file);
        formData.append('follow_up_file', follow_up_file);
        formData.append('conclusion_remarks_file', conclusion_remarks_file);



        // formData.append('medication_history', medication_history);
        formData.append('other_medications', JSON.stringify(other_medications));
        formData.append('antihypertensive_drugs', JSON.stringify(antihypertensive_drugs));
        formData.append('lipid_lowering_drugs', JSON.stringify(lipid_lowering_drugs));
        formData.append('diabetes_drugs', JSON.stringify(diabetes_drugs));
        formData.append('medication_history', JSON.stringify(medication_history));


        formData.append('examination_findings', examination_findings);
        formData.append('investigations', investigations);
        // formData.append('management', management);
        formData.append('follow_up', follow_up);
        formData.append('conclusion_remarks', conclusion_remarks);

     
       
        // formData.append('chief_complaint', chief_complaint);
        // formData.append('comorbidities', comorbidities);
        // formData.append('dyslipidemia', dyslipidemia);
        // formData.append('chronic_liver_disease', chronic_liver_disease);
        // formData.append('neurological_disorder', neurological_disorder);
        // formData.append('cardiovascular_disorders', cardiovascular_disorders);
        // formData.append('hypothyroidism', hypothyroidism);
        // formData.append('behavior_disorders', behavior_disorders);
        // formData.append('diabetes', diabetes);
        // formData.append('chronic_kidney_disease', chronic_kidney_disease);
        // formData.append('asthma', asthma);
        // formData.append('other', other);
        // formData.append('other_description', other_description);
        // formData.append('past_history', past_history);
        // formData.append('Diet', Diet);
        // formData.append('Smoking', Smoking);
        // formData.append('Smoking_Duration', Smoking_Duration);
        // formData.append('Alcohol', Alcohol);
        // formData.append('Alcohol_Duration', Alcohol_Duration);
        // formData.append('Others', Others);
        // formData.append('Sleep_Apnea', Sleep_Apnea);
        // formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        // formData.append('Exercise', Exercise);
        // formData.append('Exercise_Hours', Exercise_Hours);
        // formData.append('Exercise_Days', Exercise_Days);
        // formData.append('Use_of_Computer', Use_of_Computer);
        // formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        // formData.append('Family_History', Family_History);

        // formData.append('current_medication', JSON.stringify(current_medication));
        // formData.append('sensitivities', sensitivities);
        // formData.append('contraindicated_medication', contraindicated_medication);
        
        // formData.append('height_type', height_type);
        // formData.append('height', height);
        // formData.append('weight', weight);
        // formData.append('bmi', bmi);
        // formData.append('bmi_type', bmi_type);
        // formData.append('obesity', obesity);
        // formData.append('pulse_rate', pulse_rate);
        // formData.append('respiratory_rate', respiratory_rate);
        // formData.append('systemic_examination', systemic_examination);
        // formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        // formData.append('ecg', ecg);
        // formData.append('ecg_description', ecg_description);
        // formData.append('echo', echo);
        // formData.append('echo_description', echo_description);
        // formData.append('cbc', cbc);
        // formData.append('cbc_description', cbc_description);
        // formData.append('renal_function_test', renal_function_test);
        // formData.append('renal_function_test_description', renal_function_test_description);
        // formData.append('serum_electrolyte_level', serum_electrolyte_level);
        // formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        // formData.append('total_cholestrol', total_cholestrol);
        // formData.append('total_cholestrol_description', total_cholestrol_description);
        // formData.append('ldl_c', ldl_c);
        // formData.append('ldl_c_description', ldl_c_description);
        // formData.append('hdl_c', hdl_c);
        // formData.append('hdl_c_description', hdl_c_description);
        // formData.append('triglycerides', triglycerides);
        // formData.append('triglycerides_description', triglycerides_description);
        // formData.append('fbs', fbs);
        // formData.append('fbs_description', fbs_description);
        // formData.append('ppbs', ppbs);
        // formData.append('ppbs_description', ppbs_description);
        // formData.append('urine_analysis', urine_analysis);
        // formData.append('urine_analysis_description', urine_analysis_description);
        // formData.append('serum_potassium', serum_potassium);
        // formData.append('serum_potassium_description', serum_potassium_description);
        // formData.append('serum_creatinine', serum_creatinine);
        // formData.append('serum_creatinine_description', serum_creatinine_description);
        // formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        // formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        // formData.append('hba1c', hba1c);
        // formData.append('hba1c_description', hba1c_description);
        // formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        // formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        // formData.append('other_information', other_information);
        // formData.append('new_diagnosed', new_diagnosed);
        // formData.append('known_case_of_hypertension', known_case_of_hypertension);
        // formData.append('hypertension_description', hypertension_description);
        // formData.append('refractory', refractory);
        // formData.append('compliance_to_treatment', compliance_to_treatment);





        // formData.append('bp_goal', BP_Goal);
    
        // formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        // formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        // formData.append('Weight_loss', Weight_loss);
        // formData.append('Regular_exercise', Regular_exercise);
        // formData.append('Lifestyle_Diet', Lifestyle_Diet);
        // formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        // formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        // formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        // formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        // formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case/`+ id, formData).pipe(map(user => user));
    }

    update_followupcase_admin(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/update/`+ id, formData).pipe(map(user => user));
    }




    save_subfollowcase(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
      
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub`, formData).pipe(map(user => user));
    }

    save_subfollowcase_intrestingcase(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
      
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub`, formData).pipe(map(user => user));
    }

    save_subfollowcase_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,
        doctorId,
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);
        formData.append('doctorId', doctorId);
   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub`, formData).pipe(map(user => user));
    }


    save_subfollowcase1(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
   height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        is_followup_case,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case',is_followup_case)
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia-case-sub`, formData).pipe(map(user => user));
    }

    save_subfollowcase1_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        is_followup_case,
        followup_case,
        doctorId,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case',is_followup_case)
        formData.append('followup_case', followup_case);

        formData.append('doctorId', doctorId);

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia-case-sub`, formData).pipe(map(user => user));
    }

    update_subfollowcase(
        id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub/`+id, formData).pipe(map(user => user));
    }

    update_subfollowcase_intrestingcase(
        id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub/`+id, formData).pipe(map(user => user));
    }

    update_subfollowcase_admin(
        id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,
        doctorId,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);
        formData.append('doctorId', doctorId);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case-sub/`+id, formData).pipe(map(user => user));
    }

    update_subfollowcase1(
        id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia-case-sub/`+id, formData).pipe(map(user => user));
    }

    update_subfollowcase1_admin(
        id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        follow_after_weeks,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        seated_blood_pressure,
       lifestyle_medication,
       lifestyle_medication_remarks,
        systemic_examination,
         compliance_to_treatment,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
     
        BP_Goal,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
     
        other_medication,
        followup_case,
        doctorId,
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('follow_after_weeks', follow_after_weeks);
        formData.append('height_type', height_type);
      
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));
        formData.append('lifestyle_medication', lifestyle_medication);

        formData.append('lifestyle_medication_remarks', lifestyle_medication_remarks); 
        formData.append('systemic_examination', systemic_examination); 
        formData.append('compliance_to_treatment', compliance_to_treatment); 
   

        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
  





        formData.append('bp_goal', BP_Goal);
    
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('followup_case', followup_case);

        formData.append('doctorId', doctorId);

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia-case-sub/`+id, formData).pipe(map(user => user));
    }






    save_dys(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);
        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
    
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
    
        formData.append('other_information', other_information);
    
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia`, formData).pipe(map(user => user));
    }


    
    save_dys_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId,
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
    
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
    
        formData.append('other_information', other_information);
    
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

        formData.append('doctorId', doctorId);

        






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia`, formData).pipe(map(user => user));
    }

    update_dsy(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);
        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
  
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
  
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+ id, formData).pipe(map(user => user));
    }
    update_dsy_admin(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId,
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
  
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
  
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);

        
   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+ id, formData).pipe(map(user => user));
    }
    save_dsy_Followupcase(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
 
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
     
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   

   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia`, formData).pipe(map(user => user));
    }
    save_dsy_Followupcase_admin(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId
    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
 
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
     
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
   
        
   






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia`, formData).pipe(map(user => user));
    }
    update_dys_followupcase(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height_type,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Menstrual_History);
        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
 
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
     
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);

   




        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+ id, formData).pipe(map(user => user));
    }

    update_dys_followupcase_admin(id,
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,
        Menstrual_History,
        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination, 
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,        
        other_information,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,
        followup_case,
        doctorId,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);
        formData.append('Menstrual_History', Family_History);
        

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
 
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
     
        formData.append('other_information', other_information);
      
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));
        formData.append('is_followup_case', followup_case);
        formData.append('doctorId', doctorId);
        




        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+ id, formData).pipe(map(user => user));
    }







    get_hypertension(id) {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/pending/` + id).pipe(map(user => user));
    }

    get_intrestingcase(id) {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case/` + id).pipe(map(user => user));
    }
    get_dslipidemia(id) {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/` + id).pipe(map(user => user));
    }

    submit_followupcase(
        demographic_patient_initials,
        demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        chief_complaint,
        comorbidities,
        dyslipidemia,
        chronic_liver_disease,
        neurological_disorder,
        cardiovascular_disorders,
        hypothyroidism,
        behavior_disorders,
        diabetes,
        chronic_kidney_disease,
        asthma,
        other,
        other_description,
        past_history,
        Diet,
        Smoking,
        Smoking_Duration,
        Alcohol,
        Alcohol_Duration,
        Others,
        Sleep_Apnea,
        Erectile_Dysfunction,
        Exercise,
        Exercise_Hours,
        Exercise_Days,
        Use_of_Computer,
        Use_of_Smart_Phones,
        Family_History,

        current_medication,
        sensitivities,
        contraindicated_medication,
        height,
        weight,
        bmi,
        bmi_type,
        obesity,
        pulse_rate,
        respiratory_rate,
        systemic_examination,
        seated_blood_pressure,
        ecg,
        ecg_description,
        echo,
        echo_description,
        cbc,
        cbc_description,
        renal_function_test,
        renal_function_test_description,
        serum_electrolyte_level,
        serum_electrolyte_level_description,
        total_cholestrol,
        total_cholestrol_description,
        ldl_c,
        ldl_c_description,
        hdl_c,
        hdl_c_description,
        triglycerides,
        triglycerides_description,
        fbs,
        fbs_description,
        ppbs,
        ppbs_description,
        urine_analysis,
        urine_analysis_description,
        serum_potassium,   
        serum_potassium_description,   
        serum_creatinine,
        serum_creatinine_description,
        e_glomerular_filtration_rate,
        e_glomerular_filtration_rate_description,
        hba1c,
        hba1c_description,
        albumin_creatinine_ratio,
        albumin_creatinine_ratio_description,
        
        other_information,
  
        new_diagnosed,
        known_case_of_hypertension,
        hypertension_description,
        refractory,
        compliance_to_treatment,
        BP_Goal_140,
        BP_Goal_130,
        Salt_reduction_2g_daily,
        Moderation_of_alcohol,
        Weight_loss,
        Regular_exercise,
        Lifestyle_Diet,
        Stop_Tobacco_use,
        Lipid_lowering_drugs,
        Anti_Hypertensive_drugs,
        Diabetes_drugs,
        other_medication,


    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_subject_number', demographic_subject_number);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
        formData.append('chief_complaint', chief_complaint);
        formData.append('comorbidities', comorbidities);
        formData.append('dyslipidemia', dyslipidemia);
        formData.append('chronic_liver_disease', chronic_liver_disease);
        formData.append('neurological_disorder', neurological_disorder);
        formData.append('cardiovascular_disorders', cardiovascular_disorders);
        formData.append('hypothyroidism', hypothyroidism);
        formData.append('behavior_disorders', behavior_disorders);
        formData.append('diabetes', diabetes);
        formData.append('chronic_kidney_disease', chronic_kidney_disease);
        formData.append('asthma', asthma);
        formData.append('other', other);
        formData.append('other_description', other_description);
        formData.append('past_history', past_history);
        formData.append('Diet', Diet);
        formData.append('Smoking', Smoking);
        formData.append('Smoking_Duration', Smoking_Duration);
        formData.append('Alcohol', Alcohol);
        formData.append('Alcohol_Duration', Alcohol_Duration);
        formData.append('Others', Others);
        formData.append('Sleep_Apnea', Sleep_Apnea);
        formData.append('Erectile_Dysfunction', Erectile_Dysfunction);
        formData.append('Exercise', Exercise);
        formData.append('Exercise_Hours', Exercise_Hours);
        formData.append('Exercise_Days', Exercise_Days);
        formData.append('Use_of_Computer', Use_of_Computer);
        formData.append('Use_of_Smart_Phones', Use_of_Smart_Phones);
        formData.append('Family_History', Family_History);

        formData.append('current_medication', JSON.stringify(current_medication));
        formData.append('sensitivities', sensitivities);
        formData.append('contraindicated_medication', contraindicated_medication);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);
        formData.append('obesity', obesity);
        formData.append('pulse_rate', pulse_rate);
        formData.append('respiratory_rate', respiratory_rate);
        formData.append('systemic_examination', systemic_examination);
        formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));



        formData.append('ecg', ecg);
        formData.append('ecg_description', ecg_description);
        formData.append('echo', echo);
        formData.append('echo_description', echo_description);
        formData.append('cbc', cbc);
        formData.append('cbc_description', cbc_description);
        formData.append('renal_function_test', renal_function_test);
        formData.append('renal_function_test_description', renal_function_test_description);
        formData.append('serum_electrolyte_level', serum_electrolyte_level);
        formData.append('serum_electrolyte_level_description', serum_electrolyte_level_description);
        formData.append('total_cholestrol', total_cholestrol);
        formData.append('total_cholestrol_description', total_cholestrol_description);
        formData.append('ldl_c', ldl_c);
        formData.append('ldl_c_description', ldl_c_description);
        formData.append('hdl_c', hdl_c);
        formData.append('hdl_c_description', hdl_c_description);
        formData.append('triglycerides', triglycerides);
        formData.append('triglycerides_description', triglycerides_description);
        formData.append('fbs', fbs);
        formData.append('fbs_description', fbs_description);
        formData.append('ppbs', ppbs);
        formData.append('ppbs_description', ppbs_description);
        formData.append('urine_analysis', urine_analysis);
        formData.append('urine_analysis_description', urine_analysis_description);
        formData.append('serum_potassium', serum_potassium);
        formData.append('serum_potassium_description', serum_potassium_description);
        formData.append('serum_creatinine', serum_creatinine);
        formData.append('serum_creatinine_description', serum_creatinine_description);
        formData.append('e_glomerular_filtration_rate', e_glomerular_filtration_rate);
        formData.append('e_glomerular_filtration_rate_description', e_glomerular_filtration_rate_description);
        formData.append('hba1c', hba1c);
        formData.append('hba1c_description', hba1c_description);
        formData.append('albumin_creatinine_ratio', albumin_creatinine_ratio);
        formData.append('albumin_creatinine_ratio_description', albumin_creatinine_ratio_description);
        formData.append('other_information', other_information);
        formData.append('new_diagnosed', new_diagnosed);
        formData.append('known_case_of_hypertension', known_case_of_hypertension);
        formData.append('hypertension_description', hypertension_description);
        formData.append('refractory', refractory);
        formData.append('compliance_to_treatment', compliance_to_treatment);





        formData.append('BP_Goal_140', BP_Goal_140);
        formData.append('BP_Goal_130', BP_Goal_130);
        formData.append('Salt_reduction_2g_daily', Salt_reduction_2g_daily);
        formData.append('Moderation_of_alcohol', Moderation_of_alcohol);
        formData.append('Weight_loss', Weight_loss);
        formData.append('Regular_exercise', Regular_exercise);
        formData.append('Lifestyle_Diet', Lifestyle_Diet);
        formData.append('Stop_Tobacco_use', Stop_Tobacco_use);
        formData.append('Lipid_lowering_drugs', JSON.stringify(Lipid_lowering_drugs));
        formData.append('Anti_Hypertensive_drugs', JSON.stringify(Anti_Hypertensive_drugs));
        formData.append('Diabetes_drugs', JSON.stringify(Diabetes_drugs));
        formData.append('other_medication', JSON.stringify(other_medication));

   

     






        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case`, formData).pipe(map(user => user));
    }
  
    get_followupcase(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case/` + id).pipe(map(user => user));
    }
   
        getState() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/state`).pipe(map(user => user));
    }

    getAgeCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-age?type=`+type).pipe(map(user => user));
    }
    getHeightCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-heightcount?type=`+type).pipe(map(user => user));
    }
    getWeightCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-weightcount?type=`+type).pipe(map(user => user));
    }
    getObesityCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-Obesitycount?type=`+type).pipe(map(user => user));
    }
    getDiagnosedCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-diagnosedcount?type=`+type).pipe(map(user => user));
    }
    getknowncasehypertensioncount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-knowncasehypertensioncount?type=`+type).pipe(map(user => user));
    }
    getrefractorycount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-refractorycount?type=`+type).pipe(map(user => user));
    }
    getcompliancetotreatmentcount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-compliancetotreatmentcount?type=`+type).pipe(map(user => user));
    }

    getbp_goalcount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-bp_goalcount?type=`+type).pipe(map(user => user));
    }

    getinvestigationsCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-investigationcount?type=`+type).pipe(map(user => user));
    }
    getgenderCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-gender?type=`+type).pipe(map(user => user));
    }
    geturbanCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-placetype?type=`+type).pipe(map(user => user));
    }
    geteconomyCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-economystatus?type=`+type).pipe(map(user => user));
    }

    getcomorbiditiesount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-comorbiditiescount?type=`+type).pipe(map(user => user));
    }

    getvegcount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-vegcount?type=`+type).pipe(map(user => user));
    }

    getalcoholcount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-alcoholcount?type=`+type).pipe(map(user => user));
    }
    getsmokingcount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-smokingcount?type=`+type).pipe(map(user => user));
    }

    getdiabetescount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-diabetescount?type=`+type).pipe(map(user => user));
    }

    getSleepApneacount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-sleepApneacount?type=`+type).pipe(map(user => user));
    }

    getErectileDysfunctioncount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-ErectileDysfunctioncount?type=`+type).pipe(map(user => user));
    }

    getexercisencount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-exercisecount?type=`+type).pipe(map(user => user));
    }

    getuseofcomputercount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-useofcomputercount?type=`+type).pipe(map(user => user));
    }

    getuseofphonecount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-useofphonecount?type=`+type).pipe(map(user => user));
    }
   
    getldlcCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-ldl-c?type=`+type).pipe(map(user => user));
    }

    gethdlcCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-hdl-c?type=`+type).pipe(map(user => user));
    }

    gettgCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-triglyceride?type=`+type).pipe(map(user => user));
    }

    gettcCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-totalcholesterol?type=`+type).pipe(map(user => user));
    }

    getbgCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-bloodglucose?type=`+type).pipe(map(user => user));
    }


    getsbpCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-sbp?type=`+type).pipe(map(user => user));
    }

    getdbpCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-dbp?type=`+type).pipe(map(user => user));
    }

    gethrCount(type) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/chart/get-hr?type=`+type).pipe(map(user => user));
    }

    get_pending() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/pending`).pipe(map(user => user));
    }

    get_intrestingcase_incompletelist(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case?type=0`+`&search=`+search+`&page=`+page).pipe(map(user => user));
    }


    get_Followup() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case`).pipe(map(user => user));
    }
    get_intrestingcase_Followup(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/interesting-case?type=1`+`&search=`+search+`&page=`+page).pipe(map(user => user));
    }

    get_intrestingcase_Followups(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case/`+id).pipe(map(user => user));
    }

    get_Followups(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/followup-case/`+id).pipe(map(user => user));
    }

    get_dys_sub_Followups(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+id +'?type=1').pipe(map(user => user));
    }

    get_dyslipidemia_Followups(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia/`+id+'?type=1').pipe(map(user => user));
    }



    get_dyslipidemia(type) {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/dslipidemia?type=` + type).pipe(map(user => user));
    }
    

    get_City(id) {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/state/` + id).pipe(map(user => user));
    }

}