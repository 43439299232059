import { Component, Renderer2, isDevMode, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
// import { $ } from 'protractor';
import { environment } from '../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { timer } from 'rxjs';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  previousUrl: string;
  loadAPI: Promise<any>;
  curYear: string = (new Date()).getFullYear().toString();
  isBrowser:any;
  constructor(private renderer: Renderer2, private router: Router,@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId)
    if (this.curYear != '2019') {
      this.curYear = '2019 - ' + this.curYear;

    }
    this.router.events
      .subscribe((event) => {
      //  console.log(event instanceof NavigationEnd);
      //  console.log(event instanceof NavigationStart);
        if (event instanceof NavigationEnd) {
      
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1);

          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }



          if (currentUrlSlug == 'terms') {

            this.renderer.removeClass(document.body, 'terms');
            this.renderer.removeClass(document.body, 'temple_bg');
            this.renderer.addClass(document.body, 'white_bg');

          }

          if (currentUrlSlug == 'about') {
            this.renderer.removeClass(document.body, 'temple_bg');
            this.renderer.addClass(document.body, 'about_bg');
          }

          if (location.pathname == '/login'   || location.pathname == '/hypertensionregistryofindia/login' || location.pathname == '/verify'   || location.pathname == '/hypertensionregistryofindia/verify'   || location.pathname == '/admin'  || location.pathname == '/hypertensionregistryofindia/admin'   ||location.pathname == '/hypertensionregistryofindia/welcome'   ||location.pathname == '/hypertensionregistryofindia/signup'   ||location.pathname == '/welcome' ||location.pathname == '/signup' || location.pathname == '/hypertensionregistryofindia/forgot-password'   ||location.pathname == '/forgot-password' ||location.pathname == '/otp'  || location.pathname == '/hypertensionregistryofindia/otp'   || currentUrlSlug.includes('reset-password') || currentUrlSlug.match(/login\?returnUrl/g)|| currentUrlSlug.match(/login\?returnUrl/g) )  {
           
            this.renderer.addClass(document.body, 'login-page');
            this.renderer.addClass(document.body, 'hold-transition');
            // this.renderer.removeClass(document.body, 'hold-transition');
            // this.renderer.removeClass(document.body, 'sidebar-mini');
            // this.renderer.removeClass(document.body, 'layout-fixed');
          } else {
            
           
            if(this.isBrowser){
              timer(100).subscribe(time=>{
                $('body').removeClass('login-page');
              })
              
            }
            
          

            

            if (`${environment.appName}` + '_adminId') {
              var OneSignal = window['OneSignal'] || [];


              OneSignal.push(function () {
                OneSignal.init({
                 // appId: `${environment.oneSignalAppId}`,
                  autoRegister: false,
                  autoResubscribe: true,
                });
                OneSignal.setSubscription(true);
                OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_id'));
              });

              this.renderer.removeClass(document.body, 'login-page');
              OneSignal.push(function () {
                OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_adminId'));
              });
            }
          }
          this.previousUrl = currentUrlSlug;

        }


      });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }


    if (!isFound) {
     
        var dynamicScripts = [
          'assets/js/jquery.min.js',
          'assets/js/jquery-ui.min.js',
          'assets/js/bootstrap.bundle.min.js',
          'assets/js/moment.min.js',
          'assets/js/daterangepicker.js',
          'assets/js/tempusdominus-bootstrap-4.min.js',
          'assets/js/jquery.overlayScrollbars.min.js',
          'assets/js/adminlte.js',
          'assets/js/select2.full.min.js',
          'assets/js/bootstrap-switch.min.js',
          'assets/js/jquery.inputmask.min.js',
          'assets/js/slick.min.js',
          'assets/js/aos.js',
          'assets/js/intlTelInput.js',
          'assets/js/jquery.fancybox.min.js'



          

        ];
      }

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    
  }
}

// import { Component, Renderer2, isDevMode } from '@angular/core';
// import { Router, NavigationStart, NavigationEnd } from '@angular/router';
// import { $ } from 'protractor';
// import { environment } from '../environments/environment';

// declare var jquery: any;

// @Component({
//   selector: 'app-root',
//   templateUrl: 'app.component.html'
// })

// export class AppComponent {
//   previousUrl: string;
//   loadAPI: Promise<any>;
//   curYear: string = (new Date()).getFullYear().toString();

//   constructor(private renderer: Renderer2, private router: Router) {
//     if (this.curYear != '2019') {
//       this.curYear = '2019 - ' + this.curYear;

//     }
//     this.router.events
//       .subscribe((event) => {
//         if (event instanceof NavigationEnd) {
//           if (this.previousUrl) {
//             this.renderer.removeClass(document.body, this.previousUrl);
//           }
//           let currentUrlSlug = event.url.slice(1);

//           if (currentUrlSlug) {
//             this.renderer.addClass(document.body, currentUrlSlug);
//           }

//           if (currentUrlSlug == 'terms') {

//             this.renderer.removeClass(document.body, 'terms');
//             this.renderer.removeClass(document.body, 'temple_bg');
//             this.renderer.addClass(document.body, 'white_bg');

//           }
//           if (currentUrlSlug == 'about') {
//             this.renderer.removeClass(document.body, 'temple_bg');
//             this.renderer.addClass(document.body, 'about_bg');
//           }
//           this.previousUrl = currentUrlSlug;
//         }
//       });
//     this.loadAPI = new Promise((resolve) => {
//       this.loadScript();
//       resolve(true);
//     });
//   }

//   public loadScript() {
//     var isFound = false;
//     var scripts = document.getElementsByTagName("script")
//     for (var i = 0; i < scripts.length; ++i) {
//       if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
//         isFound = true;
//       }
//     }

//     if (!isFound) {
//       var dynamicScripts = [
//         "assets/js/jquery.min.js",
//         "assets/js/jquery-ui.min.js",
//         "assets/js/bootstrap.bundle.min.js",
//         "assets/js/moment.min.js",
//         "assets/js/daterangepicker.js",
//         "assets/js/tempusdominus-bootstrap-4.min.js",
//         "assets/js/jquery.overlayScrollbars.min.js",
//         "assets/js/adminlte.js",
//         "assets/js/adminlte.min.js",
//         'assets/js/intlTelInput.js',
//         "assets/js/select2.full.min.js",
//         "assets/js/bootstrap-switch.min.js",
//         "assets/js/jquery.inputmask.min.js",
//         "assets/js/slick.min.js",
//         "assets/js/aos.js",
//       ];
//       for (var i = 0; i < dynamicScripts.length; i++) {
//         let node = document.createElement('script');
//         node.src = dynamicScripts[i];
//         node.type = 'text/javascript';
//         node.async = false;
//         node.charset = 'utf-8';
//         document.getElementsByTagName('body')[0].appendChild(node);
//       }

//     }
//   }
// }

