import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class AuthenticationService {
    currentUserSubject: any;
    currentUser: any;
    constructor(private http: HttpClient) {

        this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem(`${environment.appName}` + '_user'));
        this.currentUser = this.currentUserSubject.asObservable();

    }
    register(first_name: string, email: string, phone: any,password,registration_number,place_of_practice,Doctors_hospital_name,state,city,code_number,profile,country_code, otp) {
       

        var formData = new FormData();
        formData.append('name', first_name);
        formData.append('email', email);
        formData.append('mobile_no', phone);
        formData.append('password', password);
        formData.append('registration_no', registration_number);
        formData.append('place_of_practice', place_of_practice);
        formData.append('doctors_hospital_name', Doctors_hospital_name);

        formData.append('state', state);
        formData.append('city', city);
        formData.append('code_number', code_number);
        formData.append('profile', null);
        if (profile) {
            formData.append('profile', profile,profile.name);
        }

        formData.append('country_code', country_code);
        formData.append('otp', otp);



        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/register`, formData).pipe(map(user => {
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.user));
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', '');
            }

            return user;
        }));
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/login`, { email: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.user));

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');


            }

            return user;
        }));

    }


    login1(user_code: Number, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/rep-login`, { user_code: user_code, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.user));

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');
                localStorage.setItem(`${environment.appName}` + 'empid', user.user.Area_Manager_empcode);
                localStorage.setItem(`${environment.appName}` + 'srempid', user.user.Sr_Manager_EmpCode);


            }

            return user;
        }));

    }

    requestToPassword(email: string){
        const formData: FormData = new FormData();
        formData.append('email', email);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/forgot-password`, formData).pipe(map(user => {
          return user;
      }));
  
      }

      updatePassword(password:string,random: string){
        const formData: FormData = new FormData();
        formData.append('random', random);
        formData.append('password', password);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/new-password/`, formData).pipe(map(user => {
              return user;
          }));
      }

      changepassword(email:string,password: string,new_password:string){
        const formData: FormData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('new_password', new_password);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/update-password`, formData).pipe(map(user => {
              return user;
          }));
      }

     

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(`${environment.appName}` + '_user_obj');
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_status');
        localStorage.removeItem(`${environment.appName}` + '_domain');
        localStorage.removeItem(`${environment.appName}` + '_admin_user_obj');
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_admin_role');
        localStorage.removeItem(`${environment.appName}` + '_admin_user');
        localStorage.removeItem(`${environment.appName}` + '_admin_adminName');
        localStorage.removeItem(`${environment.appName}` + '_admin_adminId');
        localStorage.removeItem(`${environment.appName}` + '_admin_status');
        localStorage.removeItem(`${environment.appName}` + '_admin_domain');
        localStorage.removeItem(`${environment.appName}` + '_profile_obj');
        localStorage.removeItem(`${environment.appName}` + '_profile_obj_ext');
        localStorage.removeItem(`${environment.appName}` + '_signup_obj');
        
        

        

        location.reload();
        

     
    }


    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    currentUserToken() {
        console.error(localStorage.getItem(`${environment.appName}` + '_user'));
        this.currentUserSubject.next(localStorage.getItem(`${environment.appName}` + '_user'));
    }

    public isAuth() {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
        } else {
            return false;
        }
    }



    contactquery(name: string, email: string, phone: any,subject,message) {
       

        var formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile', phone);
        formData.append('subject', subject);
        formData.append('message', message);
     
      



        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/contact-us`, formData).pipe(map(user => {
         

            return user;
        }));
    }




}