import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteConfigLoadEnd, PreloadAllModules } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  { path: 'contact-us', loadChildren: () => import('./contactus/contactus.module').then(m => m.contactusModule)},
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(m => m.galleryModule)},
  { path: 'about', loadChildren: () => import('./aboutUs/aboutUs.module').then(m => m.aboutUsModule)},
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgotpassword.module').then(m => m.forgotpasswordModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'rep-login', loadChildren: () => import('./rep_login/rep_login.module').then(m => m.repLoginModule) },
  { path: 'otp', loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule) },
  { path: 'reset-password/:id', loadChildren: () => import('./reset-password/resetpassword.module').then(m => m.resetpasswordModule) },
  { path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(m => m.welcomeModule) ,canActivate: [AuthVendorGuard] },
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.dashboardModule), canActivate: [AuthVendorGuard] },
 
  { path: 'dashboard/hypertension', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.dashboardModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/dyslipidemia', loadChildren: () => import('./dashboard_dyslipidemia/dashboard_dyslipidemia.module').then(m => m.dashboard_dyslipidemiaModule),canActivate: [AuthVendorGuard]  },
 
  { path: 'dashboard/bp-camp', loadChildren: () => import('./dashboard-bp-camp/dashboard-bp-camp.module').then(m => m.dashboardbpcampModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/Area_Manager', loadChildren: () => import('./dashboard-Area_Manager/dashboard-Area_Manager.module').then(m => m.dashboardAreaManagerModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/Regional_Manager', loadChildren: () => import('./dashboard-Regional_Manager/dashboard-Regional_Manager.module').then(m => m.dashboardRegionalManagerModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/Sales_Manager', loadChildren: () => import('./dashboard-Sales_Manager/dashboard-Sales_Manager.module').then(m => m.dashboardSalesManagerModule),canActivate: [AuthVendorGuard]  },


  { path: 'camp/create-bp-camp', loadChildren: () => import('./create-bp-camp/create-bp-camp.module').then(m => m.createbpcampModule),canActivate: [AuthVendorGuard] },
  { path: 'camp/view-incomplete-forms', loadChildren: () => import('./bpcamp_incomplete_forms/bpcamp_incomplete_forms.module').then(m => m.bpcamp_incomplete_formsModule),canActivate: [AuthVendorGuard] },
  { path: 'camp/view-Area-incomplete-forms', loadChildren: () => import('./Area-bpcamp_incomplete_forms/Area-bpcamp_incomplete_forms.module').then(m => m.Areabpcamp_incomplete_formsModule),canActivate: [AuthVendorGuard] },

  
  { path: 'camp/followup-case', loadChildren: () => import('./bpcamp_followup_case/bpcamp_followup_case.module').then(m => m.bpcamp_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'camp/MR-followup-list', loadChildren: () => import('./MR_followup_list/MR_followup_list.module').then(m => m.MR_followup_listModule),canActivate: [AuthVendorGuard] },
  { path: 'camp/Area-followup-case', loadChildren: () => import('./Area-bpcamp_followup_case/Area-bpcamp_followup_case.module').then(m => m.Areabpcamp_followup_caseModule),canActivate: [AuthVendorGuard] },

  { path: 'camp/view-case', loadChildren: () => import('./view-bp-camp/view-bp-camp.module').then(m => m.viewbpcampModule),canActivate: [AuthVendorGuard] },
  { path: 'camp/Area-view-case', loadChildren: () => import('./Area-view-bp-camp/Area-view-bp-camp.module').then(m => m.AreaviewbpcampModule),canActivate: [AuthVendorGuard] },
  



  { path: 'dashboard/Area-lipid-camp', loadChildren: () => import('./dashboard-Area-lipid-camp/dashboard-Area-lipid-camp.module').then(m => m.dashboardArealipidcampModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/lipid-camp', loadChildren: () => import('./dashboard-lipid-camp/dashboard-lipid-camp.module').then(m => m.dashboardlipidcampModule),canActivate: [AuthVendorGuard]  },
  { path: 'camp/create-lipid-camp', loadChildren: () => import('./create-lipid-camp/create-lipid-camp.module').then(m => m.createlipidcampModule),canActivate: [AuthVendorGuard] },
  { path: 'lipidcamp/view-incomplete-forms', loadChildren: () => import('./lipidcamp_incomplete_forms/lipidcamp_incomplete_forms.module').then(m => m.lipidcamp_incomplete_formsModule),canActivate: [AuthVendorGuard] },
  { path: 'lipidcamp/view-Area-incomplete-forms', loadChildren: () => import('./Area-lipidcamp_incomplete_forms/Area-lipidcamp_incomplete_forms.module').then(m => m.Arealipidcamp_incomplete_formsModule),canActivate: [AuthVendorGuard] },
 
  { path: 'lipidcamp/Area-followup-case', loadChildren: () => import('./Area-lipidcamp_followup_case/Area-lipidcamp_followup_case.module').then(m => m.Arealipidcamp_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'lipidcamp/followup-case', loadChildren: () => import('./lipidcamp_followup_case/lipidcamp_followup_case.module').then(m => m.lipidcamp_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'lipidcamp/view-case', loadChildren: () => import('./view-lipid-camp/view-lipid-camp.module').then(m => m.viewlipidcampModule),canActivate: [AuthVendorGuard] },
  { path: 'lipidcamp/Area-view-case', loadChildren: () => import('./Area-view-lipid-camp/Area-view-lipid-camp.module').then(m => m.AreaviewlipidcampModule),canActivate: [AuthVendorGuard] },


  { path: 'hypertension/create-case', loadChildren: () => import('./create-case/createcase.module').then(m => m.createcaseModule),canActivate: [AuthVendorGuard] },
  { path: 'hypertension/view-incomplete-forms', loadChildren: () => import('./hypertension_incomplete_forms/hypertension_incomplete_forms.module').then(m => m.hypertension_incomplete_formsModule),canActivate: [AuthVendorGuard] },
  { path: 'dyslipidemia/view-incomplete-forms', loadChildren: () => import('./dyslipidemia_incomplete_forms/dyslipidemia_incomplete_forms.module').then(m => m.dyslipidemia_incomplete_formsModule),canActivate: [AuthVendorGuard] },


  { path: 'hypertension/upload-document', loadChildren: () => import('./upload-document/upload-document.module').then(m => m.UploadDocumentModule), canActivate: [AuthVendorGuard] },
  { path: 'hypertension/agreement', loadChildren: () => import('./agreement/agreement.module').then(m => m.AgreementModule), canActivate: [AuthVendorGuard] },
  
  { path: 'hypertension/followup-case', loadChildren: () => import('./hypertension_followup_case/hypertension_followup_case.module').then(m => m.hypertension_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'dyslipidemia/followup-case', loadChildren: () => import('./dyslipidemia_followup_case/dyslipidemia_followup_case.module').then(m => m.dyslipidemia_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'hypertension/add-followup-case', loadChildren: () => import('./followup_case_hyper/followup_case_hyper.module').then(m => m.followup_case_hyperModule),canActivate: [AuthVendorGuard] },
  { path: 'dyslipidemia/add-followup-case', loadChildren: () => import('./followup_case_dyslipidemia/followup_case_dyslipidemia.module').then(m => m.followup_case_dyslipidemiaModule),canActivate: [AuthVendorGuard] },
  { path: 'hypertension/view-case', loadChildren: () => import('./first-case/firstcase.module').then(m => m.firstcaseModule),canActivate: [AuthVendorGuard] },
  { path: 'dyslipidemia/view-case', loadChildren: () => import('./first-case-dys/firstcasedys.module').then(m => m.firstcasedysModule),canActivate: [AuthVendorGuard] },
  { path: 'dyslipidemia/create-case', loadChildren: () => import('./create-case-dys/createcasedys.module').then(m => m.createcasedysModule),canActivate: [AuthVendorGuard]  },

  { path: 'intresting-case/create-case', loadChildren: () => import('./create-intresting-case/create-intresting-case.module').then(m => m.createIntrestingCaseModule),canActivate: [AuthVendorGuard]  },
  { path: 'dashboard/intresting-case', loadChildren: () => import('./dashboard_intresting_case/dashboard_intresting_case.module').then(m => m.dashboard_intresting_caseModule),canActivate: [AuthVendorGuard]  },
  { path: 'intresting-case/view-incomplete-forms', loadChildren: () => import('./intrestingcase_incomplete_forms/intrestingcase_incomplete_forms.module').then(m => m.intrestingcase_incomplete_formsModule),canActivate: [AuthVendorGuard] },
  { path: 'intresting-case/followup-case', loadChildren: () => import('./intrestingcase_followup_case/intrestingcase_followup_case.module').then(m => m.intrestingcase_followup_caseModule),canActivate: [AuthVendorGuard] },
  { path: 'intresting-case/view-case', loadChildren: () => import('./view-intresting-case/view-intresting-case.module').then(m => m.viewIntrestingCaseModule),canActivate: [AuthVendorGuard] },
  // { path: 'intresting-case/add-followup-case', loadChildren: () => import('./followup_case_intrestingcase/followup_case_intrestingcase.module').then(m => m.followup_case_intrestingcaseModule),canActivate: [AuthVendorGuard] },

  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.profileModule),canActivate: [AuthVendorGuard]  },
  { path: 'verify', loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule), canActivate: [AuthVendorGuard] },
  { path: 'MR-list', loadChildren: () => import('./MR/MR.module').then(m => m.MRModule),canActivate: [AuthVendorGuard]  },
  { path: 'Area-list', loadChildren: () => import('./Area/Area.module').then(m => m.AreaModule),canActivate: [AuthVendorGuard]  },

  { path: 'regional-list', loadChildren: () => import('./regional-list/regional-list.module').then(m => m.RegionallistModule),canActivate: [AuthVendorGuard]  },
  //Admin
  { path: 'chart', loadChildren: () => import('./example-chart/examplechart.module').then(m => m.examplechartModule) ,canActivate: [AuthSuperadminGuard] },
  { path: 'admin', loadChildren: () => import('./admin-login/adminlogin.module').then(m => m.AdminloginModule) },
  { path: 'home', loadChildren: () => import('./main/main.module').then(m => m.mainModule) ,canActivate: [AuthSuperadminGuard] },
  { path: 'administrators', loadChildren: () => import('./admin_settings/admin_settings.module').then(m => m.AdminSettingsModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'user-handle', loadChildren: () => import('./create_admin/create_admin.module').then(m => m.CreateAdminModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'region-handle', loadChildren: () => import('./create_region_manager/create_region_manager.module').then(m => m.CreateRegionManagerModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'area-handle', loadChildren: () => import('./create_area_manager/create_area_manager.module').then(m => m.CreateAreaManagerModule),canActivate: [AuthSuperadminGuard]  },


  { path: 'doctors-list', loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'Area-Manager', loadChildren: () => import('./Admin_Area/Admin_Area.module').then(m => m.AdminAreaModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'MR', loadChildren: () => import('./Admin_MR/Admin_MR.module').then(m => m.AdminMRModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'Regional-list', loadChildren: () => import('./Regional/Regional.module').then(m => m.RegionalModule),canActivate: [AuthSuperadminGuard]  },

  // { path: 'Area', loadChildren: () => import('./MR/MR.module').then(m => m.MRModule),canActivate: [AuthSuperadminGuard]  },

  { path: 'view-doctor', loadChildren: () => import('./view_doctor/view_doctor.module').then(m => m.ViewDoctorModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'view-patient', loadChildren: () => import('./view-patient/view-patient.module').then(m => m.PatientModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'view-case', loadChildren: () => import('./view-case/view-case.module').then(m => m.ViewCaseModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'view-follow-up', loadChildren: () => import('./view_followup/view_followup.module').then(m => m.ViewFollowupModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'dys-view', loadChildren: () => import('./dys_view/dys_view.module').then(m => m.DysViewModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'dys-sub-view', loadChildren: () => import('./dys_follow_view/dys_follow_view.module').then(m => m.DysFollowViewModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'view-activity', loadChildren: () => import('./view-activity/view-activity.module').then(m => m.ViewActivityModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'create-case-hypertension', loadChildren: () => import('./admin-create-case/admincreatecase.module').then(m => m.admincreatecaseModule),canActivate: [AuthSuperadminGuard]  },
  
  { path: 'view-interestingcase', loadChildren: () => import('./intresting-case-view/intresting-case-view.module').then(m => m.IntrestingCaseViewModule),canActivate: [AuthSuperadminGuard]  },
  { path: 'view-bp-camp', loadChildren: () => import('./bp-camp-view/bp-camp-view.module').then(m => m.bpcampViewModule),canActivate: [AuthSuperadminGuard]  },

  { path: 'view-lipid-camp', loadChildren: () => import('./lipid-camp-view/lipid-camp-view.module').then(m => m.lipidcampviewModule),canActivate: [AuthSuperadminGuard]  },

  { path: 'create-case-dyslipidemia', loadChildren: () => import('./admin-create-case-dys/admincreatecasedys.module').then(m => m.admincreatecasedysModule),canActivate: [AuthSuperadminGuard]  },

  { path: 'download-view', loadChildren: () => import('./download-case/downloadcase.module').then(m => m.downloadcaseModule), canActivate: [AuthSuperadminGuard] },
//administrators
  // { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },

  // { path: 'profile-setup', loadChildren: () => import('./profile-setup/profile-setup.module').then(m => m.ProfileSetupModule), canActivate: [AuthSuperadminGuard] },
  // { path: 'voluntery-setup', loadChildren: () => import('./voluntery-profile/voluntery-profile.module').then(m => m.VolunteryModule), canActivate: [AuthSuperadminGuard] },
  // { path: 'citizen-setup', loadChildren: () => import('./citizen-profile/citizen-profile.module').then(m => m.CitizenModule), canActivate: [AuthSuperadminGuard] },

  // { path: 'home', loadChildren: () => import('./userhome/userhome.module').then(m => m.UserHomeModule) },
  // { path: 'verify', loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule), canActivate: [AuthSuperadminGuard] },
  // { path: 'my-profile', loadChildren: () => import('./userprofile/userprofile.module').then(m => m.UserProfileModule), canActivate: [AuthSuperadminGuard] },
 
  // { path: 'my-profile/edit-profile', loadChildren: () => import('./editprofile/editprofile.module').then(m => m.editprofileModule), canActivate: [AuthSuperadminGuard] },
  
  {
    path: '**',
    redirectTo: localStorage.getItem(`${environment.appName}_admin_user`)?'admin':'',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule],
  providers: [AuthSuperadminGuard]
})
export class AppRoutingModule { }
