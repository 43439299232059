import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }
    // getType() {
    //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/user/type`).pipe(map(user => user));
    // }

    verifyMobile(mobile, email,registration_number) {
        var formData = {
            mobile_no: mobile,

            // verify: verify,
            email: email,
            registration_no:registration_number,
        }
    
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/send-otp`, formData).pipe(map(user => user));
    }


    downloadactivity(): Promise<Blob> {
        var formData={};
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/report/hyper-interesting-case`, formData,
        { responseType: 'blob' as 'json' }).toPromise();
        return file;
      }

    signup(name, email, mobile_no,password) {
        var formData = {
            name: name,
            email: email,
            mobile_no: mobile_no,
            password:password,
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/register`, formData).pipe(map(user => user));
    }

    bulk_upload(upload:File,) {
        const formData: FormData = new FormData();
       
       
        formData.append('upload', upload);
        
  
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/excel_upload`, formData).pipe(map(data => {
            return data;
        }));
  
  
        
    }
  



    uploadprofile(
        profile,


    ) {
        var formData = new FormData();
        formData.append('profile', null);
        if (profile) {
            formData.append('profile', profile, profile.name);
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/change-profile`, formData).pipe(map(user => user));
    }



    getUser() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/hypertension/user-details`).pipe(map(user => user));
    }

   



    getCountry() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-country`).pipe(map(user => user));
        //get-country
    }

    getStates() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-states`).pipe(map(user => user));
    }



    getDistricts(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-districts/` + id).pipe(map(user => user));
    }

    getDiscover() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/discover`).pipe(map(user => user));
    }


    getProfession() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/profession`).pipe(map(user => user));
    }


    followMember(id, type) {
        var formData = {
            'type': type,
            'requestId': id,
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/connection/join_or_follow`, formData).pipe(map(user => user));
    }


    getNgoCategory() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-ngos-category`).pipe(map(user => user));
    }

    getNgoType() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-ngos-type`).pipe(map(user => user));
    }

    areaOfWork() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/area-of-work`).pipe(map(user => user));
    }


    getRepresentative(search: any, page: any, filter: any) {
        const formData: FormData = new FormData();
        formData.append('search', search);
        formData.append('page', page);

        if (filter) {
            for (let val of filter) {
                formData.append('filter[]', val);
            }

        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-discover-users?isRepresentative=true`, formData).pipe(map(user => {
            return user;
        }));
    }

    getNGO(search: any, page: any) {
        const formData: FormData = new FormData();
        formData.append('search', search);
        formData.append('page', page);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-discover-users?isNGO=true`, formData).pipe(map(user => {
            return user;
        }));
    }


    getpoints() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/connection/get-points`).pipe(map(user => user));
    }

    getnotifications() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-notifications`).pipe(map(user => user));
    }

    updateCitizen(
        userId,
        type,

        mobile,
        first_name,
        last_name,
        state,
        district,
        address,
        pincode,
        voterId,
        aadhar,
        email,
        dob,
        education,
        profession,
        options,
        instagram,
        facebook,
        twitter,
        linkedin,

    ) {
        var formData = new FormData();

        formData.append('userId', userId);
        formData.append('type', type);

        formData.append('con_num', mobile);
        formData.append('name', first_name);
        formData.append('last_name', last_name);
        formData.append('state', state);
        formData.append('district', district);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('voterId', voterId);
        formData.append('aadhar', aadhar);
        formData.append('con_email', email);
        formData.append('dob', dob);
        formData.append('education', education);
        formData.append('profession', profession);
        formData.append('achievement', JSON.stringify(options));
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);
        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }




    getCitizen(search: any, page: any) {
        const formData: FormData = new FormData();
        formData.append('search', search);
        formData.append('page', page);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-discover-users?isCitizen=true`, formData).pipe(map(user => {
            return user;
        }));
    }


    getOthers(search: any, page: any, selected_id = null) {
        const formData: FormData = new FormData();
        formData.append('search', search);
        formData.append('page', page);
        if (selected_id) {
            formData.append('filterId[]', selected_id);
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/get-discover-users?isOthers=true`, formData).pipe(map(user => {
            return user;
        }));
    }

    downloadreportall(type:any): Promise<Blob> {
        var formData={
          type:type
        };
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/hypertension/report/hyper-report-all`, formData,
        { responseType: 'blob' as 'json' }).toPromise();
        return file;
      }
  


    getPosition(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dropdowns`).pipe(map(user => user));
    }


    getTaluk(district) {
        var formData = {
            'district': district
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/taluku/get/taluku`, formData).pipe(map(user => {
            return user;
        }));
    }



    updateMlaMlcProfile(
        userId,
        type,
        name,
        dob,
        address,
        pincode,
        con_num,
        con_email,
        state,
        district,
        taluk,
        con_secondarynum,
        con_secondaryemail,
        isMinistry,
        position,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('dob', dob);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('primary_contact_no', con_num);
        formData.append('primary_email', con_email);
        formData.append('state', state);
        formData.append('district', JSON.stringify(district));
        formData.append('taluku', JSON.stringify(taluk));
        formData.append('isMinsitry', isMinistry);
        formData.append('secondary_contact_no', con_secondarynum);
        formData.append('secondary_email', con_secondaryemail);
        formData.append('positions', JSON.stringify(position));
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);

        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }
    updatePanchayathProfile(
        userId,
        type,
        name,
        dob,
        address,
        pincode,
        con_num,
        con_email,
        state,
        district,
        taluk,
        panchayath,
        con_secondarynum,
        con_secondaryemail,
        isMinistry,
        position,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('dob', dob);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('primary_contact_no', con_num);
        formData.append('primary_email', con_email);
        formData.append('state', state);
        formData.append('district', JSON.stringify(district));
        formData.append('taluku', JSON.stringify(taluk));
        formData.append('panchayath', panchayath);
        formData.append('isMinsitry', isMinistry);
        formData.append('secondary_contact_no', con_secondarynum);
        formData.append('secondary_email', con_secondaryemail);
        formData.append('positions', JSON.stringify(position));
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);

        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }
    updateVipProfile(
        userId,
        type,
        name,
        dob,
        address,
        pincode,
        con_num,
        con_email,
        state,
        district,
        taluk,

        con_secondarynum,
        con_secondaryemail,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('dob', dob);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('primary_contact_no', con_num);
        formData.append('primary_email', con_email);
        formData.append('state', state);
        formData.append('district', JSON.stringify(district));
        formData.append('taluku', JSON.stringify(taluk));


        formData.append('secondary_contact_no', con_secondarynum);
        formData.append('secondary_email', con_secondaryemail);

        formData.append('instagram', instagram);
        formData.append('facebook', facebook);

        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }
    changeMonthFormatToNumber(date): number {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        months.findIndex(mon => mon === date);
        return months.findIndex(mon => mon === date) + 1;
    }



    updatengoProfile(
        userId,
        type,
        org_name,
        type_ngo,
        country,
        state,
        district,
        address,
        pincode,
        ngo_pan,
        gst,
        website,
        app,
        mobile_no,
        landline,
        ngo_category,
        con_per_name,
        con_per_email,
        con_per_primary,
        con_per_secondary,
        bank_name,
        branch_name,
        ifsc,
        acc_no,
        area_of_work,
        branch_i_area,
        branch_i_incharge,
        branch_i_address,
        branch_o_area,
        branch_o_incharge,
        branch_o_address,
        reg_cerificate,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('org_name', org_name);
        formData.append('type_ngo', type_ngo);
        formData.append('country', country);
        formData.append('state', state == '-1' ? '' : state);
        formData.append('district', district == '-1' ? '' : district);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('ngo_pan', ngo_pan);
        formData.append('gst', gst);
        formData.append('website', website);
        formData.append('app', app);
        formData.append('mobile_no', mobile_no);
        formData.append('landline', landline);
        formData.append('ngo_category', ngo_category);
        formData.append('con_per_name', con_per_name);
        formData.append('con_per_email', con_per_email);
        formData.append('con_per_primary', con_per_primary);
        formData.append('con_per_secondary', con_per_secondary);
        formData.append('bank_name', bank_name);
        formData.append('branch_name', branch_name);
        formData.append('ifsc', ifsc);
        formData.append('acc_no', acc_no);
        formData.append('area_of_work', area_of_work);
        formData.append('branch_i_area', branch_i_area);
        formData.append('branch_i_incharge', branch_i_incharge);
        formData.append('branch_i_address', branch_i_address);
        formData.append('branch_o_area', branch_o_area);
        formData.append('branch_o_incharge', branch_o_incharge);
        formData.append('branch_o_address', branch_o_address);
        if (reg_cerificate) {
            formData.append('reg_cerificate', reg_cerificate, reg_cerificate.name);
        }
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);
        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }

    updateotherProfile(
        userId,
        type,
        name,
        state,
        district,
        address,
        pincode,
        con_num,
        con_email,
        con_primary,
        con_secondary,
        con_email_primary,
        con_email_secondary,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('state', state == '-1' ? '' : state);
        formData.append('district', district == '-1' ? '' : district);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('con_num', con_num);
        formData.append('con_email', con_email);
        formData.append('con_primary', con_primary);
        formData.append('con_secondary', con_secondary);
        formData.append('con_email_primary', con_email_primary);
        formData.append('con_email_secondary', con_email_secondary);
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);
        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }

    updateMpProfile(
        userId,
        type,
        name,
        dob,
        address,
        pincode,
        con_num,
        con_email,
        state,
        district,
        con_secondarynum,
        con_secondaryemail,
        isMinistry,
        position,
        instagram,
        facebook,
        twitter,
        linkedin,
    ) {

        var formData = new FormData();
        formData.append('userId', userId);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('dob', dob);
        formData.append('address', address);
        formData.append('pincode', pincode);
        formData.append('primary_contact_no', con_num);
        formData.append('primary_email', con_email);
        formData.append('state', state);
        formData.append('district', JSON.stringify(district));
        formData.append('isMinsitry', isMinistry);
        formData.append('secondary_contact_no', con_secondarynum);
        formData.append('secondary_email', con_secondaryemail);
        formData.append('positions', JSON.stringify(position));
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);

        formData.append('twitter', twitter);
        formData.append('linkedin', linkedin);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/update-user-profile`, formData).pipe(map(user => user));
    }

    getDoctorlist() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/get-doctors`).pipe(map(user => user));
    }

    getDoctorsingle(id: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/get-doctorsingle/`+id).pipe(map(user => user));
    }

    

    getDoctorphone(id: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/get-doctorphone/`+id).pipe(map(user => user));
    }
}

