import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthRepresentativeGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var token;

        // token = of(localStorage.getItem(`${environment.appName}` + '_user'));

        if (localStorage.getItem(`${environment.appName}` + '_representative')) {
            return true;
        
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            localStorage.setItem(`${environment.appName}` + '_representative', '')
            return false;
        }

} 

}