import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class CampService {
    constructor(private http: HttpClient) { }

    save_bpcamp1(
        camp_date,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        speciality,
        options,followup_case) {
        var formData = new FormData();
        formData.append('is_followup_case', followup_case);

        console.log(options,'options');
    
        formData.append('fileresource', options.fileResource);
        formData.append('camp_image1', options.camp_image1);
        formData.append('camp_image2', options.camp_image2);
        formData.append('camp_image3', options.camp_image3);
        formData.append('camp_image4', options.camp_image4);
        formData.append('camp_image5', options.camp_image5);
        formData.append('demographic_patient_initials', options.demographic_patient_initials);
        formData.append('demographic_age_in_years', options.demographic_age_in_years);
        formData.append('demographic_gender', options.demographic_gender);
       
        formData.append('demographic_education',  options.demographic_education);
        formData.append('demographic_occupation',  options.demographic_occupation);
        formData.append('demographic_state',  options.demographic_state);
        formData.append('demographic_city',  options.demographic_city);
        formData.append('demographic_place_type',  options.demographic_place_type);

        formData.append('physician_first_name',  physician_first_name);
        formData.append('physician_place_of_practice',  physician_place_of_practice);
        formData.append('physician_doctor_hospital_name',  physician_doctor_hospital_name);
        formData.append('physician_phone_number',  physician_phone_number);
        formData.append('physician_email',  physician_email);
        formData.append('physician_state',  physician_state);
        formData.append('physician_city',  physician_city);
        formData.append('doctor_name', doctor_name);
        formData.append('physician_camp_conducted_date', camp_date);
        formData.append('physician_speciality', speciality);
       
        // formData.append('height_type', height_type);
        formData.append('height',  options.height);
        formData.append('weight',  options.weight);
        formData.append('bmi',  options.bmi);
        formData.append('bmi_type',options.bmi_type);

        formData.append('diabetes', options.Diabetes);
        formData.append('smoking',  options.Smoking);
        formData.append('sbp_value',  options.sbp_value);
        formData.append('dbp_value',  options.dbp_value);
        formData.append('hr',  options.hr);

        formData.append('other_info', options.other_info);

      
    
       
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp`, formData).pipe(map(user => user));
    }


   


    save_bpcamp(
        demographic_patient_initials,
    //    demographic_subject_number,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
//demographic_socio_economic_status,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        physician_camp_conducted_date,
        physician_speciality,

        height,
        // height_type,
        weight,
        bmi,
        bmi_type,
        diabetes,
        smoking,
        sbp_value,
        dbp_value,
        hr,
        other_info,
        other_info_file,
        camp_image1,
        camp_image2,
        camp_image3,
        camp_image4,
        camp_image5,
        followup_case,

    ) {
        var formData = new FormData();
    //          formData.append('seated_blood_pressure', JSON.stringify(seated_blood_pressure));

        formData.append('demographic_patient_initials',  JSON.stringify(demographic_patient_initials));
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender',  JSON.stringify(demographic_gender));
        formData.append('demographic_education',  JSON.stringify(demographic_education));
        formData.append('demographic_occupation',  JSON.stringify(demographic_occupation));
        formData.append('demographic_state',  JSON.stringify(demographic_state));
        formData.append('demographic_city',  JSON.stringify(demographic_city));
        formData.append('demographic_place_type',  JSON.stringify(demographic_place_type));
  //      formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
        formData.append('physician_first_name',  JSON.stringify(physician_first_name));
        formData.append('physician_place_of_practice',  JSON.stringify(physician_place_of_practice));
        formData.append('physician_doctor_hospital_name',  JSON.stringify(physician_doctor_hospital_name));
        formData.append('physician_phone_number',  physician_phone_number);
        formData.append('physician_email',  JSON.stringify(physician_email));
        formData.append('physician_state',  JSON.stringify(physician_state));
        formData.append('physician_city',  JSON.stringify(physician_city));
        formData.append('doctor_name', JSON.stringify(doctor_name));
        formData.append('physician_camp_conducted_date', physician_camp_conducted_date);
        formData.append('physician_speciality', JSON.stringify(physician_speciality));
       
        // formData.append('height_type', height_type);
        formData.append('height',  JSON.stringify(height));
        formData.append('weight',  JSON.stringify(weight));
        formData.append('bmi',  JSON.stringify(bmi));
        formData.append('bmi_type',JSON.stringify(bmi_type));

        formData.append('diabetes',  JSON.stringify(diabetes));
        formData.append('smoking',  JSON.stringify(smoking));
        formData.append('sbp_value',  JSON.stringify(sbp_value));
        formData.append('dbp_value',  JSON.stringify(dbp_value));
        formData.append('hr',  JSON.stringify(hr));

        formData.append('other_info', JSON.stringify(other_info));
        formData.append('other_info_file', other_info_file);
        formData.append('camp_image1', camp_image1);
        formData.append('camp_image2', camp_image2);
        formData.append('camp_image3', camp_image3);
        formData.append('camp_image4', camp_image4);
        formData.append('camp_image5', camp_image5);
        formData.append('is_followup_case', followup_case);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp`, formData).pipe(map(user => user));
    }


    update_bpcamp1(
        camp_date,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        speciality,options,followup_case,id) {
        var formData = new FormData();
        formData.append('is_followup_case', followup_case);

        console.log(options,'options');
    
        formData.append('fileresource', options.fileResource);
        formData.append('camp_image1', options.camp_image1);
        formData.append('camp_image2', options.camp_image2);
        formData.append('camp_image3', options.camp_image3);
        formData.append('camp_image4', options.camp_image4);
        formData.append('camp_image5', options.camp_image5);
        formData.append('demographic_patient_initials', options.demographic_patient_initials);
        formData.append('demographic_age_in_years', options.demographic_age_in_years);
        formData.append('demographic_gender', options.demographic_gender);
       
        formData.append('demographic_education',  options.demographic_education);
        formData.append('demographic_occupation',  options.demographic_occupation);
        formData.append('demographic_state',  options.demographic_state);
        formData.append('demographic_city',  options.demographic_city);
        formData.append('demographic_place_type',  options.demographic_place_type);

        formData.append('physician_first_name',  physician_first_name);
        formData.append('physician_place_of_practice',  physician_place_of_practice);
        formData.append('physician_doctor_hospital_name',  physician_doctor_hospital_name);
        formData.append('physician_phone_number',  physician_phone_number);
        formData.append('physician_email',  physician_email);
        formData.append('physician_state',  physician_state);
        formData.append('physician_city',  physician_city);
        formData.append('doctor_name', doctor_name);
        formData.append('physician_camp_conducted_date', camp_date);
        formData.append('physician_speciality', speciality);
       
        // formData.append('height_type', height_type);
        formData.append('height',  options.height);
        formData.append('weight',  options.weight);
        formData.append('bmi',  options.bmi);
        formData.append('bmi_type',options.bmi_type);

        formData.append('diabetes', options.Diabetes);
        formData.append('smoking',  options.Smoking);
        formData.append('sbp_value',  options.sbp_value);
        formData.append('dbp_value',  options.dbp_value);
        formData.append('hr',  options.hr);

        formData.append('other_info', options.other_info);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp/`+ id, formData).pipe(map(user => user));
    }


    update_bpcamp(id,
        demographic_patient_initials,
        //    demographic_subject_number,
            demographic_age_in_years,
            demographic_gender,
            demographic_education,
            demographic_occupation,
            demographic_state,
            demographic_city,
            demographic_place_type,
    //demographic_socio_economic_status,
            physician_first_name,
            physician_place_of_practice,
            physician_doctor_hospital_name,
            physician_phone_number,
            physician_email,
            physician_state,
            physician_city,
            doctor_name,
            physician_camp_conducted_date,
            physician_speciality,
    
            height,
            // height_type,
            weight,
            bmi,
            bmi_type,
            diabetes,
            smoking,
            sbp_value,
            dbp_value,
            hr,
            other_info,
            other_info_file,
            camp_image1,
        camp_image2,
        camp_image3,
        camp_image4,
        camp_image5,
            followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        //      formData.append('demographic_subject_number', demographic_subject_number);
              formData.append('demographic_age_in_years', demographic_age_in_years);
              formData.append('demographic_gender', demographic_gender);
              formData.append('demographic_education', demographic_education);
              formData.append('demographic_occupation', demographic_occupation);
              formData.append('demographic_state', demographic_state);
              formData.append('demographic_city', demographic_city);
              formData.append('demographic_place_type', demographic_place_type);
        //      formData.append('demographic_socio_economic_status', demographic_socio_economic_status);
              formData.append('physician_first_name', physician_first_name);
              formData.append('physician_place_of_practice', physician_place_of_practice);
              formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
              formData.append('physician_phone_number', physician_phone_number);
              formData.append('physician_email', physician_email);
              formData.append('physician_state', physician_state);
              formData.append('physician_city', physician_city);
      formData.append('doctor_name',doctor_name);
              formData.append('physician_camp_conducted_date',physician_camp_conducted_date);
              formData.append('physician_speciality',physician_speciality);
             
            //   formData.append('height_type', height_type);
              formData.append('height', height);
              formData.append('weight', weight);
              formData.append('bmi', bmi);
              formData.append('bmi_type', bmi_type);
      
              formData.append('diabetes', diabetes);
              formData.append('smoking', smoking);
              formData.append('sbp_value', sbp_value);
              formData.append('dbp_value', dbp_value);
              formData.append('hr', hr);
      
              formData.append('other_info',other_info);
              formData.append('other_info_file', other_info_file);
              formData.append('camp_image1', camp_image1);
              formData.append('camp_image2', camp_image2);
              formData.append('camp_image3', camp_image3);
              formData.append('camp_image4', camp_image4);
              formData.append('camp_image5', camp_image5);
              formData.append('is_followup_case', followup_case);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp/`+ id, formData).pipe(map(user => user));
    }

    get_bpcampcase(campdate) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp/single?campdate=`+ campdate).pipe(map(user => user));
    }

    get_bpcamp_incompletelist(search="",page=1,yearmonth) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp?type=0`+`&search=`+search+`&yearmonth=`+yearmonth+`&page=`+page).pipe(map(user => user));
    }


    get_bpcamp_incompletelist1(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp/incomplete?type=0`+`&page=`+page).pipe(map(user => user));
    }

  

    get_bpcamp_completelist(search="",yearmonth) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp?type=1`+`&search=`+search+ `&yearmonth=`+yearmonth).pipe(map(user => user));
    }


    Drlist(search="", yearmonthdate) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/bp_doctor_list?type=1` + `&search=`+search + `&yearmonthdate=` + yearmonthdate).pipe(map(user => user));
      }


    get_MR_completelist(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/bp-camp/MR-list?type=1`+`&search=`+search+`&page=`+page).pipe(map(user => user));
    }


    //lipid camp

    save_lipidcamp1(
        camp_date,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        speciality,options,followup_case) {
        var formData = new FormData();
           formData.append('is_followup_case',followup_case);
           formData.append('fileresource', options.fileResource);
           formData.append('camp_image1', options.camp_image1);
           formData.append('camp_image2', options.camp_image2);
           formData.append('camp_image3', options.camp_image3);
           formData.append('camp_image4', options.camp_image4);
           formData.append('camp_image5', options.camp_image5);
           formData.append('demographic_patient_initials', options.demographic_patient_initials);
           formData.append('demographic_age_in_years', options.demographic_age_in_years);
           formData.append('demographic_gender', options.demographic_gender);
          
           formData.append('demographic_education',  options.demographic_education);
           formData.append('demographic_occupation',  options.demographic_occupation);
           formData.append('demographic_state',  options.demographic_state);
           formData.append('demographic_city',  options.demographic_city);
           formData.append('demographic_place_type',  options.demographic_place_type);
   
           formData.append('physician_first_name',  physician_first_name);
           formData.append('physician_place_of_practice',  physician_place_of_practice);
           formData.append('physician_doctor_hospital_name',  physician_doctor_hospital_name);
           formData.append('physician_phone_number',  physician_phone_number);
           formData.append('physician_email', physician_email);
           formData.append('physician_state', physician_state);
           formData.append('physician_city', physician_city);
           formData.append('doctor_name', doctor_name);
           formData.append('physician_camp_conducted_date', camp_date);
           formData.append('physician_speciality', speciality);
          
           // formData.append('height_type', height_type);
           formData.append('height',  options.height);
           formData.append('weight',  options.weight);
           formData.append('bmi',  options.bmi);
           formData.append('bmi_type',options.bmi_type);
   
           formData.append('diabetes', options.Diabetes);
           formData.append('smoking',  options.Smoking);
        
           formData.append('total_cholesterol_value',  options.total_cholesterol_value);
           formData.append('ldlc_value',  options.ldlc_value);
           formData.append('hdlc_value',  options.hdlc_value);
           formData.append('triglyceride_value',  options.triglyceride_value);
           formData.append('blood_glucose_value',  options.blood_glucose_value);

           formData.append('other_info', options.other_info);
   
         
    
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp`, formData).pipe(map(user => user));
    }

    save_lipidcamp(
        demographic_patient_initials,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
         physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        physician_camp_conducted_date,
        physician_speciality,

        height,
        // height_type,
        weight,
        bmi,
        bmi_type,
        diabetes,
        smoking,
        ldlc_value,
        hdlc_value,
        triglyceride_value,
        total_cholesterol_value,
        blood_glucose_value,
        other_info,
        other_info_file,
        camp_image1,
        camp_image2,
        camp_image3,
        camp_image4,
        camp_image5,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
formData.append('doctor_name',doctor_name);
        formData.append('physician_camp_conducted_date',physician_camp_conducted_date);
        formData.append('physician_speciality',physician_speciality);
       
        // formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);

        formData.append('diabetes', diabetes);
        formData.append('smoking', smoking);
        formData.append('ldlc_value', ldlc_value);
        formData.append('hdlc_value', hdlc_value);
        formData.append('triglyceride_value',triglyceride_value);
        formData.append('total_cholesterol_value', total_cholesterol_value);
        formData.append('blood_glucose_value', blood_glucose_value);
        formData.append('other_info',other_info);
        formData.append('other_info_file', other_info_file);
        formData.append('camp_image1', camp_image1);
        formData.append('camp_image2', camp_image2);
        formData.append('camp_image3', camp_image3);
        formData.append('camp_image4', camp_image4);
        formData.append('camp_image5', camp_image5);
        formData.append('is_followup_case', followup_case);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp`, formData).pipe(map(user => user));
    }

    update_lipidcamp1(
        camp_date,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        speciality,options,followup_case,id) {
        var formData = new FormData();
     
        // formData.append('data', JSON.stringify(options));
        formData.append('is_followup_case', followup_case);
       
        console.log(options,'options');
        
           formData.append('fileresource', options.fileResource);
           formData.append('camp_image1', options.camp_image1);
           formData.append('camp_image2', options.camp_image2);
           formData.append('camp_image3', options.camp_image3);
           formData.append('camp_image4', options.camp_image4);
           formData.append('camp_image5', options.camp_image5);
           formData.append('demographic_patient_initials', options.demographic_patient_initials);
           formData.append('demographic_age_in_years', options.demographic_age_in_years);
           formData.append('demographic_gender', options.demographic_gender);
          
           formData.append('demographic_education',  options.demographic_education);
           formData.append('demographic_occupation',  options.demographic_occupation);
           formData.append('demographic_state',  options.demographic_state);
           formData.append('demographic_city', options.demographic_city);
           formData.append('demographic_place_type',  options.demographic_place_type);
   
           formData.append('physician_first_name',  physician_first_name);
           formData.append('physician_place_of_practice',  physician_place_of_practice);
           formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
           formData.append('physician_phone_number',  physician_phone_number);
           formData.append('physician_email',  physician_email);
           formData.append('physician_state',  physician_state);
           formData.append('physician_city',  physician_city);
           formData.append('doctor_name', doctor_name);
           formData.append('physician_camp_conducted_date', camp_date);
           formData.append('physician_speciality', speciality);
          
           // formData.append('height_type', height_type);
           formData.append('height',  options.height);
           formData.append('weight',  options.weight);
           formData.append('bmi',  options.bmi);
           formData.append('bmi_type',options.bmi_type);
   
           formData.append('diabetes', options.diabetes);
           formData.append('smoking',  options.smoking);
        
           formData.append('total_cholesterol_value',  options.total_cholesterol_value);
           formData.append('ldlc_value',  options.ldlc_value);
           formData.append('hdlc_value',  options.hdlc_value);
           formData.append('triglyceride_value',  options.triglyceride_value);
           formData.append('blood_glucose_value',  options.blood_glucose_value);

           formData.append('other_info', options.other_info);
   
         
        
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp/`+ id, formData).pipe(map(user => user));
    }


    update_lipidcamp(id,
        demographic_patient_initials,
        demographic_age_in_years,
        demographic_gender,
        demographic_education,
        demographic_occupation,
        demographic_state,
        demographic_city,
        demographic_place_type,
        physician_first_name,
        physician_place_of_practice,
        physician_doctor_hospital_name,
        physician_phone_number,
        physician_email,
        physician_state,
        physician_city,
        doctor_name,
        physician_camp_conducted_date,
        physician_speciality,

        height,
        // height_type,
        weight,
        bmi,
        bmi_type,
        diabetes,
        smoking,
        ldlc_value,
        hdlc_value,
        triglyceride_value,
        total_cholesterol_value,
        blood_glucose_value,
        other_info,
        other_info_file,
        camp_image1,
        camp_image2,
        camp_image3,
        camp_image4,
        camp_image5,
        followup_case,

    ) {
        var formData = new FormData();
     
        formData.append('demographic_patient_initials', demographic_patient_initials);
        formData.append('demographic_age_in_years', demographic_age_in_years);
        formData.append('demographic_gender', demographic_gender);
        formData.append('demographic_education', demographic_education);
        formData.append('demographic_occupation', demographic_occupation);
        formData.append('demographic_state', demographic_state);
        formData.append('demographic_city', demographic_city);
        formData.append('demographic_place_type', demographic_place_type);
        formData.append('physician_first_name', physician_first_name);
        formData.append('physician_place_of_practice', physician_place_of_practice);
        formData.append('physician_doctor_hospital_name', physician_doctor_hospital_name);
        formData.append('physician_phone_number', physician_phone_number);
        formData.append('physician_email', physician_email);
        formData.append('physician_state', physician_state);
        formData.append('physician_city', physician_city);
formData.append('doctor_name',doctor_name);
        formData.append('physician_camp_conducted_date',physician_camp_conducted_date);
        formData.append('physician_speciality',physician_speciality);
       
        // formData.append('height_type', height_type);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('bmi', bmi);
        formData.append('bmi_type', bmi_type);

        formData.append('diabetes', diabetes);
        formData.append('smoking', smoking);
        formData.append('ldlc_value', ldlc_value);
        formData.append('hdlc_value', hdlc_value);
        formData.append('triglyceride_value',triglyceride_value);
        formData.append('total_cholesterol_value', total_cholesterol_value);
        formData.append('blood_glucose_value', blood_glucose_value);
        formData.append('other_info',other_info);
        formData.append('other_info_file', other_info_file);
        formData.append('camp_image1', camp_image1);
        formData.append('camp_image2', camp_image2);
        formData.append('camp_image3', camp_image3);
        formData.append('camp_image4', camp_image4);
        formData.append('camp_image5', camp_image5);
        formData.append('is_followup_case', followup_case);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp/`+ id, formData).pipe(map(user => user));
    }

    get_lipidcampcase(campdate) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp/single?campdate=`+ campdate).pipe(map(user => user));
    }
    

    get_lipidcamp_incompletelist(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp/lipid-camp/?type=0`+`&search=`+search+`&page=`+page).pipe(map(user => user));
    }


    get_lipidcamp_incompletelist1(search="",page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp/incomplete/?type=0`+`&page=`+page).pipe(map(user => user));
    }

   
    get_lipidcamp_completelist(search="",yearmonth) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/camp/lipid-camp?type=1`+`&search=`+search+`&yearmonth=`+yearmonth).pipe(map(user => user));
    }

    
    get_mrbpcamp(userId:any,page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/find/` + userId + `?page=` + page).pipe(map(user => user));
    }

    get_mrlipidcamp(userId:any,page=1) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/find-lipid/` + userId + `?page=` + page).pipe(map(user => user));
    }

    get_mrincompletebpcamp(userId:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/find-incomplete-bp/` + userId).pipe(map(user => user));
    }
    
    get_mrincompletelipidcamp(userId:any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/web/mr/mr-camp/find-incomplete-lipid/` + userId).pipe(map(user => user));
    }
    

}